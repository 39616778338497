import { Link } from "react-router-dom";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { fetchTitle } from "../redux/actions/ProductActions";
import React from "react";

function PriceCardWithImg({
  image,
  title,
  name,
  description,
  sellingPrice,
  mrpPrice,
  subtxt1,
  offer,
  button,
  route,
  route1,
}) {
  const dispatch = useDispatch();
  const myContainer = useRef(null);
  const subTitle = "Lab Test Package";

  const changeState = () => {
    const newData = myContainer.current.innerText;

    dispatch(fetchTitle(subTitle + " => " + newData));
  };

  image = process.env.REACT_APP_API_URL + `/storage/${image}`;

  return (
    <div className="shadow-lg rounded-2xl bg-white md:w-96 m-auto sm:p-2 md:p-2 p-0">
      <img src={image} alt={name} className="w-96 sm:p-2 md:p-2 p-0 h-52 m-auto sm:rounded md:rounded rounded-t-xl" />
      <div className="bg-transparent m-2 p-2 rounded-lg">
        <p className="text-black text-lg font-medium " ref={myContainer}>
          {title}
          {name}
        </p>
        <p className="text-gray-400 text-sm font-medium mt-2">{subtxt1}</p>
        <Link to={route1}>
          <p className="text-gray-400 text-sm font-medium">{description}</p>
        </Link>

        <div className="flex md:items-center justify-between mt-4">
          <div>
            <p className="text-black font-medium text-lg">
              ₹ {sellingPrice}/-{" "}
            </p>
            <div className="flex gap-2 mt-1">
              <p className="text-gray-400 text-xs font-medium">{offer} </p>
              <p className="text-gray-400 text-xs font-medium">
                <s>₹ {mrpPrice}/- </s>
              </p>
            </div>
          </div>
          <Link to={route}>
            <button
              type="button"
              className="w-32 h-10 md:w-24 md:h-10 text-base font-medium rounded-full text-white bg-red-500 sm:w-24 sm:h-9"
              onClick={changeState}
            >
              {button}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
export default PriceCardWithImg;
