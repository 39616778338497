import Footer from "../../components/layouts/Footer";

import SectionImgBtnTxt from "../../components/SectionImgBtnTxt";

import homecare from "./images/homecare.jpg";
import nursing from "./images/nursing.png";
import physiotherapy from "./images/physiotherapy.png";
import diet from "./images/diet.png";
import palliative from "./images/palliative.png";
import pscycologycal from "./images/pscycologycal.png";
import BookHealthCare from "../../components/BookHealthCare";
import NewTopNavBar from "../../components/NewTopNavBar";

import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { fetchTitle } from "../../redux/actions/ProductActions";

function HomeHealthCare() {
  const dispatch = useDispatch();

  const option1 = useRef(null);
  const changeState1 = () => {
    const newData1 = option1.current.innerText;

    dispatch(fetchTitle(newData1));
  };

  const option2 = useRef(null);
  const changeState2 = () => {
    const newData2 = option2.current.innerText;

    dispatch(fetchTitle(newData2));
  };

  const option3 = useRef(null);
  const changeState3 = () => {
    const newData3 = option3.current.innerText;

    dispatch(fetchTitle(newData3));
  };

  const option4 = useRef(null);
  const changeState4 = () => {
    const newData4 = option4.current.innerText;

    dispatch(fetchTitle(newData4));
  };

  const option5 = useRef(null);
  const changeState5 = () => {
    const newData5 = option5.current.innerText;

    dispatch(fetchTitle(newData5));
  };

  const [loadComponent, setLoadComponent] = useState("Nursing");

  function componentLoader() {
    if (loadComponent === "Nursing") {
      return (
        <>
          <p className="md:text-3xl sm:text-2xl text-black font-normal">
            Nursing Care
          </p>
          <p className="font-normal text-black mt-2 text-base">
            At CallMediLife we try to provide excellent nursing care to the
            patients at their homes. Our motive is to provide good post
            medical/surgical nursing services after a patient is discharged from
            a nursing hospital. We have at our disposal dedicated well behaved
            and soft spoken nursing staff and patient attendants. Our nursing
            experts are well trained, qualified and trained. All the experts
            with us are trained to follow the discharge summary advice after the
            patient has been discharged from the hospital thus ensuring positive
            and quicker patient recovery. We can provide the home based care in
            convenient shifts as well as on short term and long term basis. We
            also monitor the working of our experts by supervising their work
            closely. CallMediLife nursing care program is highly flexible and
            can be customised as per patient needs
          </p>
        </>
      );
    }
    if (loadComponent === "Palliative") {
      return (
        <>
          <p className="md:text-3xl sm:text-2xl text-black font-normal">
            Palliative care
          </p>
          <p className="font-normal text-black mt-2 text-base">
            CallMediLife Palliative care program focuses on quality of life and
            a holistic approach that helps patients and families through serious
            illnesses. Understanding the involvement of multidisciplinary
            stakeholders a monitored approach over treatment progression is the
            key to the Palliative care. Therapy complaince, drug adherence,
            effective pain management and positive state of mind are very
            essential component especially in the Palliative care for diseases
            like Cancer. CallMediLife Palliative care program precisely takes
            care of these soft areas to improve quality of life.
          </p>
        </>
      );
    }
    if (loadComponent === "Physiotherapy") {
      return (
        <>
          <p className="md:text-3xl sm:text-2xl text-black font-normal">
            Physiotherapy
          </p>
          <p className="font-normal text-black mt-2 text-base">
            CallMediLife home Physiotherapy Services provides physiotherapy care
            in all medical conditions as prescribed by the specialist be it
            chronic disorders or acute illness or post surgery or even sports
            injury. The experienced team of professionals provide care plan at
            the convenience of a patient's home without deviating from the
            treatment plan.
          </p>
        </>
      );
    }
    if (loadComponent === "Nutrition") {
      return (
        <>
          <p className="md:text-3xl sm:text-2xl text-black font-normal">
            Diet and Nutrition
          </p>
          <p className="font-normal text-black mt-2 text-base">
            A balanced diet is one that gives your body the nutrients it needs
            to function correctly. To get the proper nutrition from your diet,
            merely consuming a good food is not sufficient. Also every
            individual is different and so is his or her health and body demand.
            While the internet is flooded with multiple dietary and nutritional
            advises one should know that a single standard formula is not that
            works for everyone. Consult our panel of dietitians, discuss your
            fitness goals and get a personalized dietary regimen for you. You
            can at your ease book a consultation with our expert dieticians and
            start your fitness journey immediately
          </p>
        </>
      );
    }
    if (loadComponent === "Psychological") {
      return (
        <>
          <p className="md:text-3xl sm:text-2xl text-black font-normal">
            Psychological counseling
          </p>
          <p className="font-normal text-black mt-2 text-base">
            Counseling sessions by psychologists help people with physical,
            emotional and mental health issues improve their sense of
            well-being, alleviate feelings of distress and resolve crises.Many a
            times people are not comfortable talking and opening up about their
            psychological concerns. Personal sessions at the comfort of home and
            over personal telephonic sessions cam prove a boon in all such
            individual needs. CallMediLife life precisely understands this fact
            of personal comfort, sensativity and empathy and has created a
            program that is flexible for individual requirements.
          </p>
        </>
      );
    }
  }

  return (
    <>
      <NewTopNavBar />

      <div className="max-w-7xl mx-auto px-4 bg-transparent-200 sm:px-6 lg:px-4 py-12">
        <SectionImgBtnTxt
          image={homecare}
          heading="Home Health Care"
          subtext1="Receive hospital-quality healthcare at the comfort of your home."
          button="Consult Now"
          route="/contactus"
        />

        <div className="md:container md:px-4 md:mx-auto flex md:flex-wrap items-center justify-between mt-12">
          <div>
            <img
              className="rounded md:w-28 md:h-28 sm:w-24 sm:h-24 w-16 h-16 m-auto"
              src={nursing}
              alt="Nursing"
              onClick={() => setLoadComponent("Nursing") || changeState1()}
            />
            <div className="text-center hidden sm:block">
              <p
                className="md:text-xl text-black font-normal mb-2"
                ref={option1}
              >
                Nursing
              </p>
            </div>
          </div>
          <div>
            <img
              className="rounded md:w-28 md:h-28 sm:w-24 sm:h-24 w-16 h-16 m-auto"
              src={palliative}
              alt="Palliative"
              onClick={() => setLoadComponent("Palliative") || changeState2()}
            />
            <div className="text-center hidden sm:block">
              <p
                className="md:text-xl text-black font-normal mb-2"
                ref={option2}
              >
                Palliative care
              </p>
            </div>
          </div>
          <div>
            <img
              className="rounded md:w-28 md:h-28 sm:w-24 sm:h-24 w-16 h-16 m-auto"
              src={physiotherapy}
              alt="Physiotherapy"
              onClick={() =>
                setLoadComponent("Physiotherapy") || changeState3()
              }
            />
            <div className="text-center hidden sm:block">
              <p
                className="md:text-xl text-black font-normal mb-2"
                ref={option3}
              >
                Physiotherapy
              </p>
            </div>
          </div>
          <div>
            <img
              className="rounded md:w-28 md:h-28 sm:w-24 sm:h-24 w-16 h-16 m-auto"
              src={diet}
              alt="Nutrition"
              onClick={() => setLoadComponent("Nutrition") || changeState4()}
            />
            <div className="text-center hidden sm:block">
              <p
                className="md:text-xl text-black font-normal mb-2"
                ref={option4}
              >
                Diet and Nutrition
              </p>
            </div>
          </div>
          <div>
            <img
              className="rounded md:w-28 md:h-28 sm:w-24 sm:h-24 w-16 h-16 m-auto"
              src={pscycologycal}
              alt="Psychological"
              onClick={() =>
                setLoadComponent("Psychological") || changeState5()
              }
            />
            <div className="text-center hidden sm:block">
              <p
                className="md:text-xl text-black font-normal mb-2"
                ref={option5}
              >
                Psychological Counseling
              </p>
            </div>
          </div>
        </div>

        <div className="border-solid shadow border-1 rounded p-4 border-gray-200 mt-2">
          {componentLoader()}
        </div>

        <BookHealthCare />
      </div>

      <Footer />
    </>
  );
}

export default HomeHealthCare;
