import { Link } from "react-router-dom";

import consultdoctor from "../components/layouts/nav-menu/consultdoctor.png";
import mydoctor from "../components/layouts/nav-menu/mydoctor.png";
import labs from "../components/layouts/nav-menu/labs.png";
import pharmacy from "../components/layouts/nav-menu/pharmacy.png";

function MenuSectionMobileScreen() {
  return (
    <div className="grid grid-cols-2 gap-2 place-items-center p-2 -mt-16 block sm:hidden -ml-6">
      <Link to="/doctor-consultation">
        <div>
          <img
            src={consultdoctor}
            alt="consult doctor"
            className="ml-5 w-16 h-16"
          />
          <p className="font-bold">Consult Doctor</p>
        </div>
      </Link>
      <Link to="/labs">
        <div>
          <img src={labs} alt="labs" className="ml-9 w-16 h-16" />
          <p className="font-bold">LabTest & Packages</p>
        </div>
      </Link>
      <Link to="/pharmacy">
        <div>
          <img src={pharmacy} alt="pharmacy" className="w-16 h-16" />
          <p className="font-bold">Pharmacy</p>
        </div>
      </Link>
      <Link to="/home-healthcare">
        <div>
          <img
            src={mydoctor}
            alt="home healthcare"
            className="ml-8 w-16 h-16"
          />
          <p className="font-bold">Home Healthcare</p>
        </div>
      </Link>
    </div>
  );
}

export default MenuSectionMobileScreen;
