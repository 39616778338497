import Footer from "./layouts/Footer";

import mission from "./images/mission.png";
import vission from "./images/vission.png";
import quality from "./images/quality.png";
import care from "./images/care.png";
import efficiency from "./images/efficiency.png";
import best from "./images/best.png";
import price from "./images/price.png";
import tech from "./images/tech.png";
import door from "./images/door.png";
import NewTopNavBar from "./NewTopNavBar";

function AboutUs() {
  return (
    <>
      <NewTopNavBar />

      <div className="max-w-7xl mx-auto px-4 bg-transparent-200 sm:px-6 lg:px-4 py-12">
        <div>
          <p className="text-4xl font-bold text-indigo-900 text-center">
            WHO WE ARE
          </p>
          <div className="mt-4">
            <p className="text-indigo-900 font-medium">
              CallMediLife HealthCare is more than just a healthcare service.
              Starting a revolution in the industry, it brings together health
              service providers under a canopy of resources.
            </p>
            <p className="text-indigo-900 py-3 font-medium">
              We span across all the major cities in the country. CallMediLife
              Healthcare Services Pvt. Ltd has a gamut of in-house services that
              includes Physiotherapy, Nursing, Attendant/Home Health Aide,
              Doctor Visit, Lab Tests, New Born Baby & Mother Care, nurses, and
              physiotherapists - and all of them at the comfort of your home.
            </p>
            <p className="text-indigo-900 py-3 font-medium">
              “An apple a day keeps the doctor away.” Well, there isn't anyone
              who hasn't come across this proverb. We wish you a million apples
              and endless days of prime health. Although, if at the slightest of
              possibility, you'd ever need a doctor, we don't want you to panic
              and run. CallMediLife will be the very support you'd need.
            </p>
            <p className="text-indigo-900 py-3 font-medium">
              We believe in the system and make it our responsibility to
              transform it by offering our users medical care through a
              responsible set of service providers managing the system
              effectively.
            </p>
          </div>
        </div>
        <div>
          <p className="text-indigo-900 py-2 font-medium">
            We present India's first hybrid map-based app which is
            multi-featured and compatible across multiple platforms.
          </p>
          <p className="text-indigo-900 py-2 font-medium">
            Furthermore, our employees go through a rigorous selection procedure
            supervised by some of the most experienced doctors in the industry.
          </p>
          <p className="text-indigo-900 py-2 font-medium">
            To add on to that, we facilitate affordable lab tests at home,
            making health care services more accessible to our customers. We
            wholeheartedly believe in our quest to provide our valuable
            customers more and more in-house health care services. We care for
            you!
          </p>
        </div>
        <div className="bg-red-100 mt-12 ">
          <div className="sm:flex items-center justify-between mt-6 p-4">
            <div className="flex-1 grid justify-items-center">
              <img src={vission} alt="Vission" className="w-20 h-20" />
              <p className="text-black text-center font-normal text-2xl">
                Our Vision
              </p>
              <p className="text-black text-center font-normal">
                We thrive to become the synonym of simplified healthcare that
                touches lives with empathy.
              </p>
            </div>
            <div className="flex-1 grid justify-items-center">
              <img src={mission} alt="Vission" className="w-20 h-20" />
              <p className="text-black text-center font-normal text-2xl">
                Our Mission
              </p>
              <p className="text-black text-center font-normal">
                We aim at creating a quality driven model with “affordability”
                at the core of it. Our strive to bridge the gap between patients
                and quality healthcare services is what makes us stand apart. We
                envision ourselves increasing accessibility to people through an
                enriched online medium.
              </p>
            </div>
          </div>
        </div>
        <div className="mt-12">
          <h6 className="text-3xl font-medium tracking-wide text-gray-800 dark:text-white md:text-3xl text-center">
            Our Values - 3 pillars of CallMediLife
          </h6>

          <div className="sm:flex items-center justify-between mt-6">
            <div className="flex-1 grid justify-items-center">
              <div>
                <img className="w-24 h-24" src={quality} alt="quality" />
              </div>
              <div className="text-center mt-4">
                <p className="text-black font-normal text-2xl">Quality</p>
                <p className="text-black font-normal">
                  Uncompromised quality with affordability remains our primary
                  pillar of success.
                </p>
              </div>
            </div>
            <div className="flex-1 grid justify-items-center">
              <div>
                <img className="w-24 h-24" src={efficiency} alt="efficiency" />
              </div>
              <div className="text-center mt-4">
                <p className="text-black font-normal text-2xl">Efficiency</p>
                <p className="text-black font-normal">
                  Achieving efficiency with stringent controls and quality
                  driven processes remains central to our ability for delivering
                  effective results.
                </p>
              </div>
            </div>
            <div className="flex-1 grid justify-items-center">
              <div>
                <img className="w-24 h-24" src={care} alt="care" />
              </div>
              <div className="text-center mt-4">
                <p className="text-black font-normal text-2xl">Care</p>
                <p className="text-black font-normal">
                  Service with empathy and care is integral to our core values
                  and act as the driving force while providing quality health
                  care to our customers.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-12">
          <div className="bg-green-100 p-4">
            <h6 className="text-3xl font-medium tracking-wide text-gray-800 dark:text-white md:text-3xl text-center">
              OUR PARTNERS IN GOOD HEALTH
            </h6>
            <p className="text-black py-2 font-medium mt-6">
              We have partnered with leading health care service providers of
              various verticals to provide quality health care to our valued
              customers.
            </p>
            <p className="text-black py-2 font-medium">
              Your good health is our primary motive and there's no way we would
              compromise on that.
            </p>
          </div>
        </div>
        <div className="mt-12">
          <h6 className="text-3xl font-medium tracking-wide text-gray-800 dark:text-white md:text-3xl text-center">
            OUR SERVICE GUARANTEE
          </h6>
          <ul className="list-disc px-4">
            <li className="text-black font-medium mt-6">
              All our customers shall be assured of high-quality, affordable,
              in-home healthcare at all times
            </li>
            <li className="text-black font-medium">
              All our health partners - Doctors, medical practioners and
              ancillary health service providers shall offer high-quality health
              care to all our customers irrespective of the location and service
              center category (primary, secondary or tertiary)
            </li>
            <li className="text-black font-medium">
              We shall ensure the best package health care services for our
              Corporate Customers (employers and businesses) and provide their
              employees with quality and affordable access to high quality
              healthcare services
            </li>
          </ul>
        </div>
        <div className="mt-12">
          <div className="sm:flex items-center justify-between">
            <div className="flex-1 grid justify-items-center">
              <img src={best} alt="best" className="w-24 h-24" />
              <p className="text-black text-center font-medium text-2xl text-red-500">
                Best Quality Assurance
              </p>
            </div>
            <div className="flex-1 grid justify-items-center">
              <img src={price} alt="price" className="w-24 h-24" />
              <p className="text-black text-center font-medium text-2xl text-red-500">
                Affordable Pricing
              </p>
            </div>
            <div className="flex-1 grid justify-items-center">
              <img src={door} alt="door" className="w-24 h-24" />
              <p className="text-black text-center font-medium text-2xl text-red-500">
                Service at your Doorstep
              </p>
            </div>
            <div className="flex-1 grid justify-items-center">
              <img src={tech} alt="tech" className="w-24 h-24" />
              <p className="text-black text-center font-medium text-2xl text-red-500">
                Superior Technology
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default AboutUs;
