function Testimonial({ image, title, description }) {
  return (
    <div className="bg-white rounded-shadow-lg md:w-1/2 flex gap-4 sm:gap-0">
      <div className="w-1/3">
        <img
          className="rounded-lg sm:rounded-full h-32"
          src={image}
          alt={title}
        ></img>
      </div>
      <div className="flex flex-col flex-1 gap-4 sm:p-2 w-2/3">
        <div>
          <p className="md:text-2xl font-medium text-gray-800 dark:text-white">
            <span className="block">{title}</span>
          </p>
        </div>
        <div>
          <p className="mb-12 md:text-xl font-normal text-gray-400 dark:text-gray-200">
            {description}
          </p>
        </div>
      </div>
    </div>
  );
}
export default Testimonial;
