function ColumnTextSubtext({ title, subtext, subtext1, subtext2 }) {
  return (
    <div>
      <ul className="list-disc">
        <li className="text-red-500 text-2xl">
          <p className="text-xl font-medium text-gray-800 dark:text-white">
            {title}
          </p>
          <p className="sm:mb-12 text-base font-normal text-gray-400 dark:text-gray-200">
            {subtext} <br></br> {subtext1} <br></br> {subtext2}
          </p>
        </li>
      </ul>
    </div>
  );
}
export default ColumnTextSubtext;
