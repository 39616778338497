import React, { useState } from "react";

const Accordion = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="accordion-item">
      <div
        className="flex justify-between bg-gray-200 p-4 border border-red-500 rounded hover:bg-gray-300"
        onClick={() => setIsActive(!isActive)}
      >
        <div>{title}</div>
        <div>{isActive ? "-" : "+"}</div>
      </div>
      {isActive && (
        <div className="p-4 bg-white border border-red-500 rounded">
          {content}
        </div>
      )}
    </div>
  );
};

export default Accordion;
