import aboutus from "../right-menu/aboutus.png";
import contactus from "../right-menu/contactus.png";
import NewRightMenu from "./NewRightMenu";

function RightMenu() {
  return (
    <div className="flex md:gap-6">
      <NewRightMenu image={aboutus} text="About Us" route="/aboutus" />
      <NewRightMenu image={contactus} text="Contact Us" route="contactus" />
    </div>
  );
}

export default RightMenu;
