import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchTitle } from "../../../redux/actions/ProductActions";

import gfamily from "./gfamily.png";
import img1 from "./img1.png";
import img2 from "./img2.png";
import img3 from "./img3.png";
import img4 from "./img4.png";
import logo from "./Logo.png";

function NewGoldPlan() {
  const dispatch = useDispatch();
  const subTitle = "Gold Plan";

  const changeState = () => {
    dispatch(fetchTitle(subTitle));
  };
  return (
    <>
      <div className="sm:flex md:flex md:p-2 sm:-mt-8 mt-10 sm:mt-0">
        <div className="flex-1 md:ml-16">
          <div className="flex gap-8">
            <img
              src={logo}
              alt="gold plan"
              className="md:h-10 md:ml-16 sm:ml-12"
            />
            <div className="sm:hidden md:hidden">
              <Link to="/contactus">
                <button
                  className="bg-red-500 font-medium p-2 rounded-full text-white text-sm w-28"
                  onClick={changeState}
                >
                  Contact Us <big className="font-bold w-24">&#8594;</big>
                </button>
              </Link>
            </div>
          </div>
          <p className="md:text-2xl font-medium text-red-500  leading-none md:ml-16 mt-4 sm:ml-12">
            Give your family the care they <br /> deserve
          </p>
          <p className="text-md font-medium text-gray-500 mt-4 leading-none sm:ml-16 md:ml-16 md:block sm:hidden">
            Unlimited consultations with Top doctors for <br />
            your entire family.
          </p>
          <p className="sm:text-base sm:font-medium sm:text-gray-500 sm:mt-4 sm:leading-none sm:ml-12 sm:block md:hidden hidden">
            Unlimited consultations with Top doctors for your entire family.
          </p>
          <img
            src={gfamily}
            alt="gold plan"
            className="md:h-72 rounded-md md:p-4 sm:p-4 md:mt-4 "
          />
          <div className="md:ml-24 sm:ml-24 hidden sm:block md:block">
            <Link to="/contactus">
              <button
                className="bg-red-500 font-medium p-2 rounded-full text-white md:text-lg sm:text-sm sm:w-44 mt-4 md:w-60"
                onClick={changeState}
              >
                Contact Us <big className="font-bold w-24">&#8594;</big>
              </button>
            </Link>
          </div>
        </div>
        <div className="flex-1 md:ml-16 sm:ml-8">
          <p className="md:text-2xl font-medium text-black">
            Benifits of &nbsp;
            <span className="text-2xl font-medium text-red-500">
              CallMediLife GoldPlan
            </span>
          </p>
          <div className="mt-6 md:space-y-9 sm:space-y-4">
            <div className="flex gap-4">
              <img
                src={img1}
                alt="Unlimited consultations"
                className="w-16 h-20"
              />
              <div className="mt-2">
                <p className="bg-indigo-600 text-white p-1 rounded-sm font-medium h-8 w-56">
                  Unlimited Free Consultations
                </p>
                <p className="text-gray-500 font-medium leading-none md:block sm:hidden">
                  Get unlimited free consultations with all <br /> doctors at
                  any time
                </p>
                <p className="sm:text-gray-500 sm:font-medium sm:leading-none sm:text-sm md:hidden sm:block hidden">
                  Get unlimited free consultations with all doctors at any time
                </p>
              </div>
            </div>
            <div className="flex gap-4">
              <img src={img2} alt="Health Problems" className="w-16 h-20" />
              <div className="mt-2">
                <p className="bg-indigo-600 text-white p-1 rounded-sm font-medium h-8 w-56">
                  Free For All Health Problems
                </p>
                <p className="text-gray-500 font-medium leading-none md:block sm:hidden">
                  Consult with any doctor from 18 departments <br /> at any time
                </p>
                <p className="sm:text-gray-500 sm:font-medium sm:leading-none sm:text-sm md:hidden sm:block hidden">
                  Consult with any doctor from 18 departments at any time
                </p>
              </div>
            </div>
            <div className="flex gap-4">
              <img src={img3} alt="Full Family" className="w-16 h-20" />
              <div className="mt-2">
                <p className="bg-indigo-600 text-white p-1 rounded-sm font-medium h-8 w-40">
                  Free For Full Family
                </p>
                <p className="text-gray-500 font-medium sm:leading-none md:text-base sm:text-sm">
                  Free consultations for upto 6 family members
                </p>
              </div>
            </div>
            <div className="flex gap-4">
              <img src={img4} alt="Entire year" className="w-16 h-20" />
              <div className="mt-2">
                <p className="bg-indigo-600 text-white p-1 rounded-sm font-medium h-8 w-40">
                  One Time Yearly Fee
                </p>
                <p className="text-gray-500 font-medium sm:leading-none md:text-base sm:text-sm">
                  Pay once Rs. 1999 once for entire year
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default NewGoldPlan;
