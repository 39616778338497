import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import React, { useRef} from "react";
import { fetchTitle } from "../../../../src/redux/actions/ProductActions";

function NewRightMenu({ image, text, route }) {

  const dispatch = useDispatch();
  const myContainer = useRef();

  const changeState = () => {
    const newData = myContainer;
    dispatch(fetchTitle(newData));
  };

  return (
    <Link to={route} onClick={changeState}>
      <div className="text-center mt-6 mr-2">
        <div className="px-2 flex items-center uppercase leading-snug text-white hover:opacity-75">
          <div className="mx-auto">
            <img
              className="object-center object-cover md:h-7 md:w-7 sm:h-6 sm:w-6 hidden md:block sm:block"
              src={image}
              alt={text}
            />
          </div>
        </div>
        <div className="text-center">
          <p className="text-black font-bold text-xs md:text-sm sm:text-sm sm:hidden md:block">
            {text}
          </p>
        </div>
      </div>
    </Link>
  );
}

export default NewRightMenu;
