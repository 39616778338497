import Footer from "./layouts/Footer";

import NewTopNavBar from "./NewTopNavBar";

function PrivacyPolicy() {
  return (
    <>
      <NewTopNavBar />
      <div className="max-w-7xl mx-auto px-4 bg-transparent-200 sm:px-6 lg:px-4 py-12">
        <p className="text-4xl font-bold text-black text-left">
          Privacy Policy
        </p>
        <p className="text-black mt-6 font-normal">
          CallMediLife understands the importance of valuing privacy. It
          believes in maintaining confidentiality, security and integrity of
          information of all the users. The following privacy policies give an
          explanation on we collect, share, use, disclose, and protect personal
          information of the Users of the Services (Including the website
          visitors).
        </p>
        <p className="text-black mt-3 font-normal">
          This Privacy Policy is published in compliance of the (Indian)
          Information Technology Act, 2000 and the rules, regulations,
          guidelines and clarifications framed there under, including the
          (Indian) Information Technology (Reasonable Security Practices and
          Procedures and Sensitive Personal Information) Rules, 2011 (SPI
          Rules).
        </p>
        <p className="text-black mt-3 font-normal">
          Please read the policies carefully before indulging in the website in
          order to know how your personal information is treated.
        </p>
        <p className="text-black mt-3 font-normal">
          By visiting and/or accessing the Website, you agree to this Privacy
          Policy.
        </p>
        <ol className="list-decimal mt-6 font-medium text-lg p-3 md:p-0">
          <li>
            COLLECTION OF PERSONAL INFORMATION
            <div>
              <p className="text-black font-normal text-base">
                We may ask you for your personal information when you connect
                with us through calls, emails, or other means of contact in
                order to access our Services. You hereby consent to the
                collection of such information by CallMediLife. The information
                collected by us will involve:
              </p>
              <ul className="list-disc font-normal sm:ml-16 text-base p-2 sm:p-0">
                <li>
                  Contact data (such as your full name, email address and phone
                  number).
                </li>
                <li>
                  Demographic data (such as your gender, your date of birth and
                  your pin code).
                </li>
                <li>
                  Data regarding your usage of the services and history of the
                  appointments made by or with you through the use of Services.
                </li>
                <li>
                  Data regarding your medical records history; and insurance
                  data.
                </li>
                <li>
                  Other information that you voluntarily choose to provide to us
                  (such as information shared by you with us through emails or
                  letters).
                </li>
                <li>
                  The information collected may be deemed as ‘sensitive personal
                  data or information’ under the SPI rules.
                </li>
              </ul>
              <p className="text-black mt-2 font-normal text-base">
                “Personal Information” is defined under the SPI Rules to mean
                any information that relates to a natural person, which, either
                directly or indirectly, in combination with other information
                available or likely to be available to a body corporate, is
                capable of identifying such person. The SPI Rules further define
                “Sensitive Personal Data or Information” of a person to mean
                personal information about that person relating to:
              </p>
              <ul className="list-disc font-normal sm:ml-16 text-base p-2 sm:p-0">
                <li>
                  Contact data (such as your full name, email address and phone
                  number).
                </li>
                <li>
                  Demographic data (such as your gender, your date of birth and
                  your pin code).
                </li>
                <li>
                  Data regarding your usage of the services and history of the
                  appointments made by or with you through the use of Services.
                </li>
                <li>
                  Data regarding your medical records history; and insurance
                  data.
                </li>
                <li>
                  Other information that you voluntarily choose to provide to us
                  (such as information shared by you with us through emails or
                  letters).
                </li>
                <li>
                  The information collected may be deemed as ‘sensitive personal
                  data or information’ under the SPI rules.
                </li>
              </ul>
              <p className="text-black mt-2 font-normal text-base">
                CallMediLife may provide your personal information to third
                party that works with or on behalf of us in order to provide a
                smooth service. Your consent to use your personal information is
                restricted to the aforementioned purpose only.
              </p>
              <p className="text-black mt-2 font-normal text-base">
                However, Company will be free to use, collect and disclose
                information that is freely available about you in the public
                domain without your consent.
              </p>
            </div>
          </li>
          <li className="mt-2">
            CONTROLLERS OF PERSONAL INFORMATION
            <p className="text-black font-normal">
              Your personal data will be stored and collected by CallMediLife
            </p>
          </li>
          <li className="mt-2">
            PURPOSES OF COLLECTION OF YOUR DATA
            <p className="text-black font-normal text-base">
              CallMediLife collects your information when you visit the website,
              register for a service, or when you use its products and services.
              You cease to be anonymous once you register with us. Furthermore,
              you may receive SMS(s) and notifications about our service at the
              number that you registered with.
            </p>
            <p className="text-black mt-2 font-normal text-base">
              Hence, by registering you authorize the Company to send texts and
              email alerts to you with your login details and any other service
              requirements, including promotional mails and SMSs.
            </p>
            <p className="text-black mt-2 font-normal text-base">
              Further, In order to avail some of the Services, the Users may be
              required to upload copies of their prescriptions, on the Website
              and/ or e-mail the same to Company in accordance with the Terms of
              Use and the prescriptions will be stored/ disclosed by Company
              only in the manner specified in this Privacy Policy and the Terms
              of Use. The term personal information/data shall also include any
              such prescriptions uploaded or otherwise provided by Users.
            </p>
            <p className="text-black mt-2 font-normal text-base">
              Furthermore, Company may keep records of telephone calls received
              and made for making inquiries, orders, or other purposes for the
              purpose of administration of Services.
            </p>
            <ul className="list-disc font-normal sm:ml-16 text-base p-2 sm:p-0">
              <li>Register you as customer/user on the Website.</li>
              <li>
                Dealing with requests, enquiries or complaints and other
                customer care related activities; and all other general
                administrative and business purposes.
              </li>
              <li>
                Process your orders or applications and provision of products
                and services.
              </li>
              <li>
                Administer or otherwise carry out our obligations in relation to
                any agreement with our business partners/contractors.
              </li>
              <li>
                Research and development and for User administration (including
                conducting User surveys).
              </li>
              <li>Technical administration and customization of Website.</li>
              <li>
                To send you information about special promotions or offers
                (either offered by the Company or by its business partners). We
                might also tell you about new features or products. These might
                be our own offers or products, or third-party offers or products
                with whom Company has a tie-up.
              </li>
              <li>
                Improvement of Services and features on the Website. In this
                regard, we may combine information we get from you with
                information about you we get from third parties.
              </li>
              <li>
                To send you notices, communications, offer alerts relevant to
                your use of the Services offered on this Websiteas otherwise
                provided in this Privacy Policy.
              </li>
            </ul>
          </li>
          <li className="mt-2">
            INFORMATION SHARING, TRANSFER AND DISCLOSURE
            <p className="text-black font-normal text-base">
              We may need to disclose/ transfer your personal information to the
              following third parties for the purposes mentioned in this Privacy
              Policy and the Terms of Use:
            </p>
            <p className="text-black mt-2 font-normal text-base">
              To business partners and other service providers appointed by us
              for the purpose of carrying out services on our behalf under a
              contract.
            </p>
            <p className="text-black mt-2 font-normal text-base">
              We may also share, sell, and/or transfer your personal information
              to any successor-in-interest as a result of a sale of any part of
              our business or upon the merger, reorganization, or consolidation
              of it with another entity on a basis that it is not the surviving
              entity. We may also disclose or transfer your Information, to
              another third party as part of reorganization or a sale of the
              assets of a Company’s corporation division or company. Any third
              party, to which we transfer or sell our assets, will have the
              right to continue to use the personal data and/ or other
              information that you have provided to us.
            </p>
            <p className="text-black mt-2 font-normal text-base">
              To government institutions/ authorities to the extent required a)
              under the laws, rules, and regulations and or under orders of any
              relevant judicial or quasi-judicial authority; b) to protect and
              defend the rights or property of the Company; c) to fight fraud
              and credit risk; d) to enforce our Terms of Use (to which this
              Privacy Policy is also a part) ; or e) when Company, in its sole
              discretion, deems it necessary in order to protect its rights or
              the rights of others.
            </p>
            <p className="text-black mt-2 font-normal text-base">
              If otherwise required by an order under any law for the time being
              in force including in response to enquiries by Government agencies
              for the purpose of verification of identity, or for prevention,
              detection, investigation including cyber incidents, prosecution,
              and punishment of offences.
            </p>
            <p className="text-black mt-2 font-normal text-base">
              In case of any contests or surveys conducted by the Company in
              which the you participate, your information may be disclosed to
              third parties, also be disclosed to third parties to the extent
              necessary for fulfillment of any offer/vouchers etc. and other
              aspects of such contest or similar offering.
            </p>
            <p className="text-black mt-2 font-normal text-base">
              We make all your personal Information accessible to our employees
              and data processors only on a need-to-know basis. All our
              employees and data processors, which have access to, and are
              associated with the processing of your Information, are obliged to
              respect its confidentiality.
            </p>
            <p className="text-black mt-2 font-normal text-base">
              Non-personally identifiable information may be disclosed to third
              party ad servers, ad agencies, technology vendors and research
              firms to serve advertisements to the Users. Company may also share
              its aggregate findings (not specific information) based on
              information relating to your internet use to prospective,
              investors, strategic partners, sponsors and others in order to
              help growth of our business. These companies may use information
              (excluding your name, address, email address, or telephone number)
              about your visits to this Website in order to provide
              advertisements on this Website and other third party websites
              about goods and services that may be of interest to you. We use
              third-party service providers to serve ads on our behalf across
              the internet and sometimes on this Website. They may collect
              anonymous information about your visits to Website, and your
              interaction with our products and services. They may also use
              information about your visits to this and other websites for
              targeted advertisements for goods and services. This anonymous
              information is collected through the use of a pixel tag, which is
              industry standard technology used by most major websites. No
              personally identifiable information is collected or used in this
              process.
            </p>
            <p className="text-black mt-2 font-normal text-base">
              We may make anonymous or aggregate personal information and
              disclose such data only in a non-personally identifiable manner.
              Such information does not identify you individually.
            </p>
            <p className="text-black mt-2 font-normal text-base">
              Access to your account information and any other personal
              identifiable information is strictly restricted and used only in
              accordance with specific internal procedures, in order to operate,
              develop or improve our Services. We may use third party service
              providers to enable you to provide with our services and we
              require such third parties to maintain the confidentiality of the
              information we provide to them under our contracts with them.
            </p>
          </li>
          <li className="mt-2">
            WE COLLECT COOKIES
            <p className="text-black font-normal text-base">
              We may also receive and/or hold information about the User’s
              browsing history including the URL of the site that the User
              visited prior to visiting the website as well as the Internet
              Protocol (IP) address of each User's computer (or the proxy server
              a User used to access the World Wide Web), User's computer
              operating system and type of web browser the User is using as well
              as the name of User's ISP.
            </p>
            <p className="text-black mt-2 font-normal text-base">
              The Website uses temporary cookies to store certain data (that is
              not sensitive personal data or information) that is used by the
              Company and its service providers for the technical administration
              of the Website, research and development, and for User
              administration. A cookie is a piece of data stored on the user's
              computer tied to information about the user. We may use both
              session ID cookies and persistent cookies. For session ID cookies,
              once you close your browser or log out, the cookie terminates and
              is erased. A persistent cookie is a small text file stored on your
              computer’s hard drive for an extended period of time.
            </p>
          </li>
          <li className="mt-2">
            UNSUSCRIBE TO E-MAILS
            <p className="text-black font-normal text-base">
              To opt out from receiving e-mails about announcements and
              marketing information, e-mail your request at:
            </p>
          </li>
          <li className="mt-2">
            SECURITY
            <p className="text-black font-normal text-base">
              We employ appropriate technical and organizational security
              measures at all times to protect the information we collect from
              you. However, methods over internet are never 100% secure.
              Therefore, we cannot guarantee its absolute security. Furthermore,
              the confidentiality and security of your login details are in your
              hands.
            </p>
          </li>
          <li className="mt-2">
            THIRD PARTY ADVERTISING
            <p className="text-black font-normal text-base">
              We may use third-party advertising companies and/or ad agencies to
              serve ads when you visit our Website. These companies may use
              information (excluding your name, address, email address, or
              telephone number) about your visits to this Website in order to
              provide advertisements on this Website and other third party
              websites about goods and services that may be of interest to you.
            </p>
          </li>
          <li className="mt-2">
            LINKS TO OTHER WEBSITES
            <p className="text-black font-normal text-base">
              There might be affiliates or other sites linked to the Website. We
              are not responsible for the privacy of the personal information
              that you provide to these websites.
            </p>
          </li>
          <li className="mt-2">
            CHANGES IN THIS PRIVACY POLICY
            <p className="text-black font-normal text-base">
              Company reserves the right to change this policy from time to
              time, with or without advance notice, at its sole discretion. We
              may update this privacy policy to reflect changes to our
              information practices. We encourage you to periodically visit this
              webpage.
            </p>
          </li>
          <li className="mt-2">
            GRIEVANCE OFFICER
            <p className="text-black font-normal text-base">
              In case you have any grievances with respect to in accordance with
              applicable law on Information Technology and rules made there
              under, the name and contact details of the Grievance Officer are
              provided below:
            </p>
          </li>
        </ol>
      </div>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
