// COMPONENTS
import Footer from "../../components/layouts/Footer";
import SectionHeading from "../../components/SectionHeading";
import ProgressBar from "../../components/ProgressBar";
import Profile from "../../components/Profile";
import SectionImgText from "../../components/SectionImgText";
import NewTopNavBar from "../../components/NewTopNavBar";
import UploadPrescription from "../../components/UploadPrescription";

// IMAGES
import skincare from "./images/skincare.png";
import weightmanagement from "./images/weightmanagement.png";
import painrelief from "./images/painrelief.png";
// import userexp from "./images/userexp.jpg";
import DrVankt from "../home/DrVankt.jpg";
import blank from "../home/blank.jpg";

function Pharmacy() {
  return (
    <>
      {/* <TopNavBar /> */}
      <NewTopNavBar />

      <div className="max-w-7xl mx-auto px-4 bg-transparent-200 sm:px-6 lg:px-4 py-12">
        <UploadPrescription />

        {/* BROWSE MEDS BY DISEASE */}
        <div className="mt-20">
          <SectionHeading
            title="Browse Medicines By Disease"
            subtitle="Health Condition"
            textcolor="text-red-500"
          />
          <div className="flex flex-col justify-center mx-10 md:mx-0 sm:mx-0 md:flex-row sm:flex-nowrap sm:flex-row mt-6 md:justify-between sm:justify-between sm:gap-4">
            <SectionImgText image={skincare} text="Skin Care" />
            <SectionImgText image={weightmanagement} text="Weight Management" />
            <SectionImgText image={painrelief} text="Pain Relief" />
          </div>
        </div>
      </div>
      {/* PROGRESS BAR */}
      <ProgressBar />

      {/* PROFILE */}
      <div className="max-w-7xl mx-auto px-4 bg-transparent-200 sm:px-6 lg:px-4 py-6">
        <div className="py-8">
          <div className="mt-12 md:flex gap-6">
            <Profile
              image={DrVankt}
              description="CallMediLife Healthcare has created a trust in the doctors and patients alike for high quality and reliability in diagnostics. Keep up the good work. Wishing you all, all the best."
              name="Dr. G Venkateshwar"
              designation="MBBS, MD - General Medicine -Mumbai"
            />
            <Profile
              image={blank}
              description="CallMediLife gives us great competitive prices, and quick accurate results with online access to our numbers. but the thing I'm most impressed with is the Customer Service. Always energetic, cheerful, helpful, accurate and quick. A welcome anomaly in today's world."
              name="Mr Swapnesh Shinde"
              designation="DigiSprash-Manager"
            />
            <Profile
              image={blank}
              description="CallMediLife Healthcare has created a trust in the doctors and patients alike for high quality and reliability in diagnostics. Keep up the good work. Wishing you all, all the best."
              name="Dr. Nilesh Wankhade"
              designation="MMBS, General Medicine -Mumbai"
            />
          </div>
        </div>
      </div>

      {/* FOOTER */}
      <div className="mt-10">
        <Footer />
      </div>
    </>
  );
}
export default Pharmacy;
