export const accordionData = [
  {
    title:
      "How long do I have to wait to use my Vouchers once I've purchased it?",
    content: `You can use most Vouchers immediately upon purchase.`,
  },
  {
    title: "Do I need to book in advance when using my Vouchers?",
    content: `Advance booking is essential when redeeming your Vouchers unless otherwise stated. Any further redemption instructions will be included in the Fine Print section.`,
  },
  {
    title: "Do I need to mention callmedilife.com when booking?",
    content: `Please mention that you are a callmedilife.com customer when booking your session.`,
  },
  {
    title: "How do I book?",
    content: `There will be instructions and relevant contact details on your Voucher.`,
  },
  {
    title:
      "If I order a product, how do I check the status of my order if it hasn't reached me within the estimated time frame?",
    content: `Email to contact@callmedilife.com or call us at toll-free +91 8652544377`,
  },
  {
    title: "If I'm running late for an appointment will they accommodate me?",
    content: `In most cases the bookings are allocated the amount of time required for the treatment and will usually have appointments back to back so they are likely to be unable to accommodate you if you arrive late. In most cases a minimum of 24 hours-notice is required to amend/cancel a booking. Failing to do so may cause you to forfeit the full or partial value of your voucher.`,
  },
  {
    title: "What is the Cancellation Policy for Service Vouchers?",
    content: `Within the limits of reason, we'll do what it takes to make you happy but unless there is something wrong with the purchase. For vouchers related to Services, we do operate a 7-day cancellation period (a working day is any day that is not a Saturday, Sunday or a Bank Holiday in the Republic of India), so if you are unhappy with your purchase, have changed your mind or have purchased too many vouchers/more vouchers than needed please e-mail contact@callmedilife.com within 7 working days from the date of purchase from the email account registered with us relaying your feedback on the Offer and the codes you wish us to cancel. Alternatively, you can also write to us at CallMediLife HealthCare Services Private Limited, Office No. 02, Harsh Vihar Co-operative Society, Building No 31, Sector 1, Shanti Nagar, Mira Road (East) Thane - 401107 regarding the cancellation or call us at +91 8652544377. On doing so, a cancellation will be processed for you and an email confirmation sent when your request has been actioned. Please note that vouchers cannot be cancelled after its expiry date or redemption.`,
  },
  {
    title: "What is the Refund policy?",
    content: `Unless, the Offers are non-refundable in nature and specifically mentioned to be so on the Offer itself, If any of the purchases didn't match up to your expectations, expired before you redeemed it, or you've changed your mind after purchasing it, no worries. Just let us know and we will give you a no-questions-asked reversal. Your feedback though would be highly appreciated, as we are ever eager to learn, happy to respond and believe in constant improvement.`,
  },
];
