import { Link } from "react-router-dom";

function SmallIconText({ image, text, route }) {
  return (
    <Link to={route}>
      <div className="text-center hidden sm:block">
        <div className="px-2 flex items-center uppercase leading-snug text-white hover:opacity-75">
          <div className="mx-auto">
            <img
              className="object-center object-cover md:h-12 md:w-12 sm:h-8 sm:w-8 md:block sm:block"
              src={image}
              alt={text}
            />
          </div>
        </div>
        <div className="text-center">
          <p className="text-black font-bold text-xs md:text-base sm:text-sm sm:hidden md:block">
            {text}
          </p>
        </div>
      </div>
    </Link>
  );
}

export default SmallIconText;
