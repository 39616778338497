import { ActionTypes } from "../constants/ActionTypes";

const initialState = {
  title: "",
};

export const ProductReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.FETCH_TITLE:
      return { ...state, title: payload };
    default:
      return state;
  }
};
