import { Link } from "react-router-dom";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { fetchTitle } from "../redux/actions/ProductActions";

function PriceCardTitleImgBtn({ image, title, price, button, classes, route }) {
  const dispatch = useDispatch();
  const myContainer = useRef(null);

  const changeState = () => {
    const newData = myContainer.current.innerText;

    dispatch(fetchTitle(newData));
  };

  return (
    <div
      className={`shadow-lg rounded-xl bg-indigo-900 md:w-60 md:h-80 mt-2 sm:h-80 ${classes}`}
    >
      <img
        src={image}
        alt={title}
        className="md:w-60 md:h-48 sm:w-52 sm:h-44 h-36 w-full"
      />
      <div className="text-center">
        <p className="text-white text-sm font-medium py-1" ref={myContainer}>
          {title}
        </p>
        <p className="text-gray-50 text-xl font-bold py-2">{price}</p>
        <div className="items-center justify-between py-2 ">
          <Link to={route}>
            <button
              type="button"
              className="w-32 h-7 text-base font-medium rounded-full text-white bg-red-600 hover:bg-red-700"
              onClick={changeState}
            >
              {button}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
export default PriceCardTitleImgBtn;
