// COMPONENTS
import SectionHeading from "../../components/SectionHeading";
import SectionImgBtnTxt from "../../components/SectionImgBtnTxt";
import RoundedBackgroundImgtxt from "../../components/RoundedBackgroundImgTxt";
import Footer from "../../components/layouts/Footer";
import RoundedBgImgWithSideTxt from "../../components/RoundedBgImgWithSideTxt";
import NewTopNavBar from "../../components/NewTopNavBar";
import PriceCardWithoutImg from "../../components/PriceCardWithoutImg";
import PriceCardWithImg from "../../components/PriceCardWithImg";

// IMAGES
import labs from "./images/labs.png";
import select from "./images/select.png";
import chat from "./images/chat.png";
import prescription from "./images/prescription.png";
import home from "./images/home.png";
import offer from "./images/offer.png";
import report from "./images/report.png";
import associate from "./images/associate.png";
import { Link } from "react-router-dom";

import React, { useEffect } from "react";
import { setPackages } from "../../redux/actions/PackageActions";
import { setAlllabtestgroups } from "../../redux/actions/AlllabtestgroupActions";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";

function Labs() {
  const packages = useSelector((state) => state.allPackages.packages);
  const labtestGroup = useSelector(
    (state) => state.allLabtestgroup.alllabtestgroup
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const res = await axios
          .get(process.env.REACT_APP_API_URL + "/api/packages")
          .then((cmlPackage) => cmlPackage.data.packages);
        dispatch(setPackages(res));
      } catch (err) {
        console.log("Err", err);
      }
    };
    fetchPackages();
  }, [dispatch]);

  useEffect(() => {
    const fetchLabtestgroups = async () => {
      try {
        const res = await axios.get(
          process.env.REACT_APP_API_URL + `/api/labtestGroups`
        );
        dispatch(setAlllabtestgroups(res.data.labtestGroups));
      } catch (err) {
        console.log("Err", err);
      }
    };
    fetchLabtestgroups();
  }, [dispatch]);

  return (
    <>
      {/* <TopNavBar /> */}
      <NewTopNavBar />
      <div className="max-w-7xl mx-auto px-4 bg-transparent-200 sm:px-6 lg:px-4 py-12">
        <SectionImgBtnTxt
          image={labs}
          filter="filter blur-sm"
          heading="Health Checkups"
          subtext1="Get lab tests and full body checkups from the comfort of your home."
          subtext2="Upto 45% OFF + Get 10% Health Cashback."
          button="See All Packages"
          font="font-bold"
          route="/allPackages"
        />
        {/* TOP BOOKED DAIGNOSTIC TEST */}
        <div className="mt-16">
          <div className="flex justify-between">
            <SectionHeading title="Book Popular Routine Lab Tests" />
            <Link to="/allTests">
              <p className="text-lg mt-1 font-medium tracking-wide text-indigo-900 dark:text-white md:text-xl hover:underline md:mr-6">
                See All Tests
              </p>
            </Link>
          </div>
          <div className="sm:flex justify-center mx-0 md:mx-0 sm:flex-wrap sm:flex-row md:flex-nowrap md:justify-between sm:justify-center md:gap-2 sm:gap-4">
          {labtestGroup.filter((cmlLabtest) => cmlLabtest.isPopular === 0 ).slice(0, 5).map((cmlLabtest) => {
          const { id, name, sellingPrice, mrpPrice } = cmlLabtest;
          return(
            <div key={id}>
              <PriceCardWithoutImg
                title={name}
                subtxt1="20% Off"
                offer={mrpPrice}
                price={sellingPrice}
                button="Book Now"
                route="/contactus"
              />
            </div>
            );
          })}
          </div>
        </div>

        {/* Popular Health Checkup Packages */}
        <div className="mt-16">
          <div className="flex justify-between">
            <SectionHeading title="Popular Health Checkup Packages" />
            <Link to="/allPackages">
              <p className="text-lg mt-1 font-medium tracking-wide text-indigo-900 dark:text-white md:text-xl hover:underline mr-6">
                See All Packages
              </p>
            </Link>
          </div>
          <div className="flex flex-col mx-2 md:flex-row sm:flex-col sm:gap-2 md:gap-2 gap-4 mt-12">
           {packages.slice(0,3).map((cmlpackage) => {
            const { id, name, description, image, sellingPrice, mrpPrice } = cmlpackage;
            return (
              <PriceCardWithImg
               key={id}
               image={image}
               name={name}
               description={description}
               sellingPrice={sellingPrice}
               mrpPrice={mrpPrice}
               button="Book Now"
               route="/contactus"
               route1={`/package-lab-test-group/` +id}
              />
              ); 
              })
            }
          </div>
        </div>
      </div>

      {/* WHY BOOK WITH US */}
      <div className="mt-16">
        <div className="bg-gray-200 dark:bg-gray-800 md:px-24">
          <div className="p-5 md:p-8 sm:p-6">
            <div className="">
              <p className="text-center text-3xl text-black font-medium">
                Why book with us?
              </p>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 md:gap-y-8 mt-10 md:mt-12 sm:mt-12 gap-x-4 gap-y-8">
              <RoundedBgImgWithSideTxt
                image={home}
                title="Home sample collection for FREE"
                text="A certified professional will collect your sample from a
                      location of your preference."
              />
              <RoundedBgImgWithSideTxt
                image={associate}
                title="CallMediLife Associate Labs"
                text="Labs with industry standards.To ensure the accuracy of reports"
              />
              <RoundedBgImgWithSideTxt
                image={report}
                title="Digital Report"
                text="Acess your reports anytime on your CallMediLife account.We will email you a copy."
              />
              <RoundedBgImgWithSideTxt
                image={offer}
                title="Offers and affordable prices"
                text="Get great discounts and offers on tests and packages."
              />
            </div>
          </div>
        </div>
      </div>

      {/* HOW IT WORKS */}
      <div className="max-w-7xl mx-auto px-4 bg-transparent-200 sm:px-6 lg:px-4 py-12">
        <div className="sm:mt-8 md:mt-10 mt-8">
          <SectionHeading title="How it Works" />
          <div className="container m-8 mx-auto sm:flex flex-col md:flex-row sm:flex-row items-center justify-between">
            <RoundedBackgroundImgtxt
              image={select}
              text="Select a speciality or symptom"
            />
            <RoundedBackgroundImgtxt
              image={chat}
              text="Chat / Video call with a verified doctor"
            />
            <RoundedBackgroundImgtxt
              image={prescription}
              text="Get a digital prescription and a free follow-up chat"
            />
          </div>
        </div>
      </div>

      {/* FOOTER */}
      <Footer />
    </>
  );
}
export default Labs;
