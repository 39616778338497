import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer>
      <div className="justify-between md:flex sm:flex md:px-24 sm:px-10 px-5 py-3 bg-indigo-900 mb-3">
        <div>
          <p className="text-red-500">SERVICES</p>
          <ul className="text-white text-sm md:py-4">
            <li className="font-semibold"> Corporate Services:</li>
            <li>
              <Link to="/services">Customised Wellness Program</Link>
            </li>
            <li>
              <Link to="/services">Access To Primary Care</Link>
            </li>
            <li>
              <Link to="/services">Health Risk Management</Link>
            </li>
            <li>
              <Link to="/services">Employee Assistance Program </Link>
            </li>
            <li>
              <Link to="/services">Annual Health Checks</Link>{" "}
            </li>
            <li className="font-semibold mt-2"> Insurance Services:</li>
            <li>
              <Link to="/services"> Pre-policy Health Checks</Link>
            </li>
            <li>
              <Link to="/services"> Nationwide OPD Network</Link>
            </li>
            <li>
              <Link to="/services">Customised And Speacialised Network</Link>
            </li>
          </ul>
        </div>
        <div>
          <p className="text-red-500">HEALTHCARE SERVICES</p>
          <ul className="text-white text-sm md:py-4">
            <li>
              <Link to="/doctor-consultation">Doctors </Link>
            </li>
            <li>
              <Link to="/labs">Daignostics </Link>
            </li>
            <li>
              <Link to="/labs">Health Checkups</Link>
            </li>
            <li>
              <Link to="/pharmacy">Pharmacy</Link>
            </li>
            <li>
              <Link to="/home-healthcare">Home Healthcare</Link>
            </li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
        <div>
          <p className="text-red-500">COMPANY</p>
          <ul className="text-white text-sm md:py-4">
            <li>
              <Link to="/aboutus">About</Link>
            </li>
            <li>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/terms">Terms and condition</Link>
            </li>
            <li>
              <Link to="/cancellation">Cancellation and refund policy</Link>
            </li>
            <li>&nbsp;</li>
            <li>&nbsp;</li>
            <li className="text-red-500 text-lg">Follow Us on:</li>
            <li>
              <label className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75">
                <a href="https://www.facebook.com/CallMediLife/">
                  <label className="fab fa-facebook text-lg leading-lg text-white opacity-75" />
                </a>
                &nbsp;&nbsp;&nbsp;
                <a href="https://www.instagram.com/callmedilife/?hl=en">
                  <label className="fab fa-instagram text-lg leading-lg text-white opacity-75" />
                </a>
                &nbsp;&nbsp;&nbsp;
                <a href="https://twitter.com/callmedilife">
                  <label className="fab fa-twitter text-lg leading-lg text-white opacity-75" />
                </a>
              </label>
            </li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
        <div>
          <p className="text-red-500">NEED HELP?</p>
          <ul className="text-white text-sm md:py-4">
            <li>
              <Link to="/faqs">FAQs</Link>
            </li>
            <li>&nbsp;</li>
            <li className="text-red-500 text-lg">CONTACT US</li>
            <li>+91 1800 266 3227</li>
            <li>contact@callmedilife.com</li>
            <li>7.30AM to 8.30PM (All Days) </li>
            <li>CallMediLife Healthcare Services Pvt Ltd </li>
            <li>Unit No. 1002,10th floor,</li>
            <li>Jay Antariksh,Thakur House,Makwana Road,</li>
            <li>Marol Andheri East,</li>
            <li>Mumbai, Maharashtra 400059</li>
            <li></li>
          </ul>
        </div>
      </div>
    </footer>
  );
}
export default Footer;
