import { useState } from "react";
import { useDispatch } from "react-redux";
import { fetchTitle } from "../../redux/actions/ProductActions";

// COMPONENTS
import Footer from "../../components/layouts/Footer";
import SectionHeading from "../../components/SectionHeading";
import ProgressBar from "../../components/ProgressBar";
import ColumnTextSubtext from "../../components/ColumnTextSubtext";
import Profile from "../../components/Profile";
import RoundedBackgroundImgtxt from "../../components/RoundedBackgroundImgTxt";
import CircleImgCardBtn from "../../components/CircleImgCardBtn";
import PriceCardTitleImgBtn from "../../components/PriceCardTitleImgBtn";
import NewTopNavBar from "../../components/NewTopNavBar";

// IMAGES
import consultdoctor from "./images/consultdoctor.png";
import cough from "./images/cough.png";
import skin from "./images/skin.png";
import depression from "./images/depression.png";
import offer1 from "./images/offer1.png";
import offer2 from "./images/offer2.png";
import select from "./images/select.png";
import chat from "./images/chat.png";
import prescription from "./images/prescription.png";
import DrVankt from "../home/DrVankt.jpg";
import blank from "../home/blank.jpg";
import onlinedr from "./images/onlinedr.png";
import { Link } from "react-router-dom";

function DoctorConsultation() {
  const [selectSpecialist, setSelectSpecialist] = useState(false);

  const [selects, setSelects] = useState();

  const dispatch = useDispatch();
  const subTitle = "Consult Doctor";

  const changeState = () => {
    dispatch(fetchTitle(subTitle + " => " + selects));
  };

  return (
    <div>
      {/* <TopNavBar /> */}

      <NewTopNavBar />

      <div className="max-w-7xl mx-auto px-4 bg-transparent-200 sm:px-6 lg:px-4 py-12">
        <div className="bg-white dark:bg-gray-800 overflow-hidden relative flex">
          <div className="">
            <img
              alt="heading"
              src={consultdoctor}
              className="absolute h-full w-full lg:block top-0"
            />
          </div>
          <div className="text-start py-12 px-4 sm:px-6 lg:py-16 lg:px-8 z-20">
            <h2 className="text-3xl font-extrabold text-black dark:text-white sm:text-4xl">
              <p className="text-4xl font-medium dark:text-white text-gray-800">
                <span className="block">Consult Doctor</span>
              </p>
              <p className="text-left mt-6 text-lg font-normal text-gray-800 dark:text-white">
                <span className="block">Take Online Doctor Consultation</span>
              </p>
              <p className="text-left mb-6 text-lg font-normal text-gray-800 dark:text-white">
                <span className="block">
                  Private Consultation + Audio Call. Starts at just Rs.199
                </span>
              </p>
            </h2>
            <div className="lg:mt-0 lg:flex-shrink-0">
              <div className="mt-2 inline-flex ">
                <button
                  className="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => setSelectSpecialist(true)}
                >
                  Consult Now
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* NEW SECTION */}
        {selectSpecialist ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="bg-red-300 mt-12 rounded-lg sm:p-8 p-4 sm:w-2/3 w-4/5">
                <div className="grid sm:grid-cols-2">
                  <p className="md:text-4xl sm:text-2xl text-xl font-medium dark:text-white text-gray-800">
                    ONLINE DOCTORS
                  </p>
                  <button
                    className="md:text-4xl sm:text-2xl text-2xl justify-self-end sm:-mt-6 -mt-10"
                    onClick={() => setSelectSpecialist(false)}
                  >
                    &times;
                  </button>
                </div>
                <div className="mt-8 flex md:gap-6 sm:gap-20 gap-4">
                  <select
                    value={selects}
                    className="md:w-2/3 sm:w-48 p-2 rounded-full md:text-lg"
                    onChange={(e) => setSelects(e.target.value)}
                  >
                    <option>Select Specialist</option>
                    <option>Cardiologist</option>
                    <option>Diabetologist</option>
                    <option>Dietician</option>
                    <option>Gastroenterologist</option>
                    <option>General Physician</option>
                    <option>Gynaecologist</option>
                    <option>Oncologist</option>
                    <option>Orthopaedician</option>
                    <option>Paediatrician</option>
                    <option>Psychiatrist</option>
                  </select>
                  <Link to="/contactus">
                    <button
                      className="bg-red-600 w-auto text-white active:bg-red-700 md:font-bold uppercase sm:font-normal font-bold text-xs p-2 sm:text-sm md:px-6 md:py-3 sm:px-4 sm:py-2 rounded-full shadow hover:shadow-lg
                      outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      onClick={changeState}
                    >
                      Consult Now
                    </button>
                  </Link>
                </div>
                <div className="flex mt-2">
                  <div className="md:w-3/5 sm:w-1/2">
                    <p className="mt-8 md:text-lg font-normal">
                      Find and consult best specialist doctors of the field. Our
                      online doctor facility aid doctor opinion to our customers
                      at click away.
                    </p>
                    <p className="mt-8 md:text-lg font-normal">
                      Chat with the doctor or book a telephonic consultation to
                      get appropriate medical help or even one may even seek a
                      second opinion for the existing medical condition
                    </p>
                  </div>
                  <img
                    src={onlinedr}
                    alt="online doctor"
                    className="md:w-2/5 sm:w-1/2 hidden sm:block"
                  />
                </div>
              </div>
            </div>
          </>
        ) : null}

        {/* COMMON HEALTH CONCERN */}
        <div className="mt-12">
          <SectionHeading
            title="Common Health Concern"
            subtitle="Consult a doctor online for any health issue"
          />
          <br></br>
          <div className="container md:px-4 md:py-8 mx-auto md:flex sm:flex md:flex-wrap items-center justify-between sm:gap-6 grid grid-cols-2 gap-4">
            <PriceCardTitleImgBtn
              image={cough}
              title="Cough and Cold"
              price="Rs 250/-"
              button="Consult Now"
              route="/contactus"
            />
            <PriceCardTitleImgBtn
              image={skin}
              title="Skin Problems"
              price="Rs 250/-"
              button="Consult Now"
              route="/contactus"
            />
            <PriceCardTitleImgBtn
              image={depression}
              title="Depression and Anxiety"
              price="Rs 250/-"
              button="Consult Now"
              route="/contactus"
            />
          </div>
        </div>

        {/* OFFERS */}
        <div className="py-12">
          <SectionHeading title="Offers" />
          <div className="sm:flex sm:gap-4 sm:py-8 space-y-4 sm:space-y-0">
            <CircleImgCardBtn
              image={offer1}
              text="Get Unlimited Consultation starting at Rs.200/ month"
              button="Get Membership"
              route="/contactus"
            />
            <CircleImgCardBtn
              image={offer2}
              text="Consult with Specialist at just Rs.199/-"
              button=" Consult Now"
              classes="text-right"
              route="/contactus"
            />
          </div>
        </div>

        {/* Benifits of Online Consultation */}
        <div className="py-4">
          <SectionHeading title="Benifits of Online Consultation" />
          <div className="grid sm:grid-cols-3 sm:gap-10 py-12 px-6">
            <ColumnTextSubtext
              title="Consult Top Doctors 24x7"
              subtext="Connect instantly with a 24x7 speacialist or choose to video
                    visit a particular doctor"
            />
            <ColumnTextSubtext
              title="Convenient and Easy"
              subtext="Start an instant consultation within 2 minutes or do a video
                    consultation at the scheduled time"
            />
            <ColumnTextSubtext
              title="100% Safe Consultations"
              subtext="Be assured that your online consultation will be fully
                    private and secured"
            />
            <ColumnTextSubtext
              title="Similar Clinic Experience"
              subtext="Experience clinic-like consultation through a video call
                    with the doctor.Video consultation is available only on the
                    Practo app"
            />
            <ColumnTextSubtext
              title="Free Follow-upt"
              subtext="Get a valid digital prescription and a 3-day free follow-up
                    chat for further clarifications"
            />
          </div>
        </div>

        {/* HOW IT WORKS */}
        <div>
          <SectionHeading title="How it Works" />
          <div className="container m-12 px-4 mx-auto md:flex sm:flex md:flex-wrap items-center justify-between">
            <RoundedBackgroundImgtxt
              image={select}
              text="Select a speciality or symptom"
            />
            <RoundedBackgroundImgtxt
              image={chat}
              text="Chat / Video call with a verified doctor"
            />
            <RoundedBackgroundImgtxt
              image={prescription}
              text="Get a digital prescription and a free follow-up chat"
            />
          </div>
        </div>
      </div>

      {/* PROGRESS BAR */}
      <ProgressBar />

      {/* FAQ's */}
      <div className="max-w-7xl mx-auto px-4 bg-transparent-200 sm:px-6 lg:px-4 py-12">
        <div className="py-4">
          <SectionHeading title="FAQ's" />
          <div className="grid sm:grid-cols-2 gap-5 py-12 px-6">
            <ColumnTextSubtext
              title="What is online doctor consultation"
              subtext="Online doctor consultation or online medical consultation is a method"
              subtext1="...Read More"
            />
            <ColumnTextSubtext
              title="Is online doctor consultation safe and secured on
              Callmedilife?"
              subtext="The privacy of our patients is crital to us and thus we are compil"
              subtext1="...Read More"
            />
            <ColumnTextSubtext
              title="Are your online doctors qualified?"
              subtext="We follow a strict verification process for every doctor providing online"
              subtext1="medical services on Callmedilife.Our team manually veriifes necessary"
              subtext2="documents,registration and certifications for every doctor."
            />
            <ColumnTextSubtext
              title="Can I do a free online doctor consultation on Callmedilife?"
              subtext="Avail a free online consultation with top doctors in India during the ...Read More"
            />
          </div>
        </div>

        {/* USERS CHAT EXPERIENCE */}
        <div className="py-2">
          <div className="flex">
            <SectionHeading title="Users online chat experience" />
          </div>
          <div className="mt-12 md:flex gap-6">
            <Profile
              image={DrVankt}
              description="CallMediLife Healthcare has created a trust in the doctors and patients alike for high quality and reliability in diagnostics. Keep up the good work. Wishing you all, all the best."
              name="Dr. G Venkateshwar"
              designation="MBBS, MD - General Medicine -Mumbai"
            />
            <Profile
              image={blank}
              description="CallMediLife gives us great competitive prices, and quick accurate results with online access to our numbers. but the thing I'm most impressed with is the Customer Service. Always energetic, cheerful, helpful, accurate and quick. A welcome anomaly in today's world."
              name="Mr Swapnesh Shinde"
              designation="DigiSprash-Manager"
            />
            <Profile
              image={blank}
              description="CallMediLife Healthcare has created a trust in the doctors and patients alike for high quality and reliability in diagnostics. Keep up the good work. Wishing you all, all the best."
              name="Dr. Nilesh Wankhade"
              designation="MMBS, General Medicine -Mumbai"
            />
          </div>
        </div>
      </div>
      {/* FOOTER */}
      <Footer />
    </div>
  );
}
export default DoctorConsultation;
