import { BrowserRouter, Switch, Route } from "react-router-dom";

import Home from "./pages/home/Home";
import DoctorConsultation from "./pages/doctor-consultation/DoctorConsultation";
import Pharmacy from "./pages/pharmacy/Pharmacy";
import Labs from "./pages/labs/Labs";
import HomeHealthCare from "./pages/home-healthcare/HomeHealthCare";
import AboutUs from "./components/AboutUs";
import ContactUs from "./components/ContactUs";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Terms from "./components/Terms";
import Cancellation from "./components/Cancellation";
import ScrollToTop from "./components/ScrollToTop";
import AllPackages from "./components/AllPackages";
import AllTests from "./components/AllTests";
import Services from "./components/Services";
import FAQS from "./components/FAQS";
import PackageLabTestGroup from "./components/PackageLabTestGroup";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/doctor-consultation">
            <DoctorConsultation />
          </Route>
          <Route path="/pharmacy">
            <Pharmacy />
          </Route>
          <Route path="/labs">
            <Labs />
          </Route>
          <Route path="/home-healthcare">
            <HomeHealthCare />
          </Route>
          <Route path="/aboutus">
            <AboutUs />
          </Route>
          <Route path="/contactus">
            <ContactUs />
          </Route>
          <Route path="/privacy-policy">
            <PrivacyPolicy />
          </Route>
          <Route path="/terms">
            <Terms />
          </Route>
          <Route path="/cancellation">
            <Cancellation />
          </Route>
          <Route path="/allPackages">
            <AllPackages />
          </Route>
          <Route path="/allTests">
            <AllTests />
          </Route>
          <Route path="/services">
            <Services />
          </Route>
          <Route path="/faqs">
            <FAQS />
          </Route>
          <Route path="/package-lab-test-group/:id">
            <PackageLabTestGroup />
          </Route>
        </Switch>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
