function RoundedBgImgWithSideTxt({ image, title, text }) {
  return (
    <div className="flex">
      <div>
        <div className="rounded-full bg-white w-14 h-14 md:w-20 md:h-20 md:p-4 sm:w-16 sm:h-16 sm:p-3">
          <img className="rounded w-10 h-14 md:w-14 md:h-12 sm:w-12 sm:h-10 m-auto" src={image} alt={title} />
        </div>
      </div>
      <div className="ml-5 sm:w-80">
        <div>
          <p className="md:text-xl sm:text-lg font-medium">{title}</p>
        </div>
        <div className="sm:mt-2 md:mt-2 sm:mb-3 md:w-66">
          <p className="text-sm text-gray-500 font-normal">{text}</p>
        </div>
      </div>
    </div>
  );
}
export default RoundedBgImgWithSideTxt;
