import { Link } from "react-router-dom";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { fetchTitle } from "../redux/actions/ProductActions";

function SectionImgBtnTxt({
  image,
  heading,
  subtext1,
  subtext2,
  button,
  font,
  filter,
  route,
}) {
  const dispatch = useDispatch();
  const myContainer = useRef(null);

  const changeState = () => {
    const newData = myContainer.current.innerText;

    dispatch(fetchTitle(newData));
  };

  return (
    <>
      <div className="bg-white dark:bg-gray-800 overflow-hidden relative flex">
        <div className="">
          <img
            alt={heading}
            src={image}
            className={`absolute h-full w-full lg:block top-0 ${filter}`}
          />
        </div>
        <div className="text-start py-12 px-4 sm:px-6 lg:py-16 lg:px-8 z-20">
          <h2 className="text-3xl font-extrabold text-black dark:text-white sm:text-4xl">
            <p className="text-4xl font-medium dark:text-white text-gray-800">
              <span className="block" ref={myContainer}>
                {heading}
              </span>
            </p>
            <p
              className={`text-left mt-6 text-lg font-normal text-gray-800 dark:text-white ${font}`}
            >
              <span className="block">{subtext1} </span>
            </p>
            <p
              className={`text-left mb-6 text-lg font-normal text-gray-800 dark:text-white ${font}`}
            >
              <span className="block">{subtext2}</span>
            </p>
          </h2>
          <div className="lg:mt-0 lg:flex-shrink-0">
            <div className="mt-2 inline-flex ">
              <Link to={route}>
                <button
                  className="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={changeState}
                >
                  {button}
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default SectionImgBtnTxt;
