function SectionHeading({ title, subtitle, classes, textcolor }) {
  return (
    <div className="text-left">
      <h6
        className={`text-3xl font-medium tracking-wide text-gray-800 dark:text-white md:text-3xl ${classes}`}
      >
        {title}
      </h6>
      <p className={`text-base text-gray-400 font-normal ${textcolor}`}>
        {subtitle}
      </p>
    </div>
  );
}
export default SectionHeading;
