import CircleBackgroundImgTitle from "../../../components/CricleBackgroundImgTitle";

import dentist from "./dentist.png";
import dermatologist from "./dermatologist.png";
import gynecologist from "./gynecologist.png";
import neurologist from "./neurologist.png";
import cardiologist from "./cardiologist.png";

function ConsultDoctor({ title, subtitle }) {
  return (
    <div className="mt-16 hidden sm:block">
      <div className="text-center">
        <h6 className="text-3xl font-medium tracking-wide text-gray-800 dark:text-white md:text-3xl">
          {title}
        </h6>
        <p className="text-base text-gray-400 font-normal">{subtitle}</p>
      </div>
      <br></br>
      <div className="container md:px-4 mx-auto md:flex sm:flex md:flex-wrap items-center justify-between sm:gap-3 grid grid-cols-2 gap-x-6">
        <CircleBackgroundImgTitle image={dentist} title="Dentist" />
        <CircleBackgroundImgTitle image={cardiologist} title="Cardiologist" />
        <CircleBackgroundImgTitle image={dermatologist} title="Dermetologist" />
        <CircleBackgroundImgTitle image={neurologist} title="Neurologist" />
        <CircleBackgroundImgTitle image={gynecologist} title="Gynecologist" />
      </div>
    </div>
  );
}

export default ConsultDoctor;
