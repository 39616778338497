import { Link } from "react-router-dom";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { fetchTitle } from "../redux/actions/ProductActions";

function PriceCardWithoutImg({
  title,
  subtxt1,
  subtxt2,
  offer,
  price,
  button,
  classes,
  route,
}) {
  const dispatch = useDispatch();
  const myContainer = useRef(null);
  const subTitle = "Lab Test";

  const changeState = () => {
    const newData = myContainer.current.innerText;

    dispatch(fetchTitle(subTitle + " => " + newData));
  };

  return (
    <div
      className={`shadow-lg rounded-xl bg-transparent w-full md:w-60 sm:w-48 gap-5 h-auto mt-7 ${classes}`}
    >
      <div className="py-4 flex justify-between sm:flex-col sm:text-center">
        <div className="px-5 sm:px-0">
        <p className="text-black text-lg font-medium" ref={myContainer}>
          {title}
        </p>
        <p className="text-gray-500 text-sm font-medium">{subtxt1}</p>
        <p className="text-gray-500 text-sm font-medium">{subtxt2}</p>
        <div className="flex flex-row md:justify-center sm:flex-col md:flex-col md:justify-center">
          <p className="text-gray-600 text-sm font-medium mt-2 mr-3 sm:mr-0">
            <s>₹ {offer}/-</s>
          </p>
          <p className="text-black text-lg font-medium sm:py-4 md:py-4 py-1">₹ {price}/-</p>
        </div>
        </div>
        <div>
        <Link to={route}>
          <button
            type="button"
            className="w-24 h-9 md:w-40 h-9 m-6 sm:m-0 text-base font-medium rounded-full text-white bg-red-500 hover:bg-red-700 sm:w-24"
            onClick={changeState}
          >
            {button}
          </button>
        </Link>
        </div>
      </div>
    </div>
  );
}
export default PriceCardWithoutImg;
