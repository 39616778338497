import { ActionTypes } from "../constants/ActionTypes";
const intialState = {
  alllabtestgroup: [],
};

export const alllabtestgroupsReducer = (state = intialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_ALLLABTESTGROUPS:
      return { ...state, alllabtestgroup: payload };
    default:
      return state;
  }
};