import SmallIconText from "../../SmallIconText";
import consultdoctor from "./consultdoctor.png";
import mydoctor from "./mydoctor.png";
import pharmacy from "./pharmacy.png";
import labs from "./labs.png";

function NavMenu() {
  return (
    <ul className="flex md:pr-40 sm:pr-24 sm:pl-8 md:pt-2 mx-auto font-semibold font-heading  md:space-x-12 sm:space-x-8 md:mt-4 sm:mt-6 ">
      <li>
        <SmallIconText
          image={consultdoctor}
          text="Consult Doctor"
          route="/doctor-consultation"
        />
      </li>
      <li>
        <SmallIconText image={labs} text="LabTest & Packages" route="/labs" />
      </li>

      <li>
        <SmallIconText image={pharmacy} text="Pharmacy" route="/pharmacy" />
      </li>
      <li>
        <SmallIconText
          image={mydoctor}
          text="Home Health Care"
          route="/home-healthcare"
        />
      </li>
    </ul>
  );
}

export default NavMenu;
