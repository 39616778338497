import { combineReducers } from "redux";
import { ProductReducer } from "./ProductReducers";
import { packagesReducer } from "./PackageReducers";
import { labtestgroupsReducer } from "./LabtestgroupReducers";
import { alllabtestgroupsReducer } from "./AlllabtestgroupReducers";
import { labtestsReducer } from "./LabtestReducers";

const reducers = combineReducers({
  allTitles: ProductReducer,
  allPackages: packagesReducer,
  allLabtestgroups: labtestgroupsReducer,
  allLabtestgroup: alllabtestgroupsReducer,
  allLabtests: labtestsReducer,
});

export default reducers;