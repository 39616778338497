import { Link } from "react-router-dom";

function SectionBgImgTxtBtn({ image, title, subtext, button, route }) {
  return (
    <div className="bg-white dark:bg-gray-800 overflow-hidden relative flex">
      <div className="">
        <img
          alt={title}
          src={image}
          className="absolute h-full sm:h-full md:h-full w-full sm:w-full md:w-full lg:block top-0"
        />
      </div>
      <div className="text-start py-12 px-4 sm:px-6 lg:py-16 lg:px-8 z-20">
        <h2 className="text-3xl font-extrabold text-black dark:text-white sm:text-4xl">
          <p className="text-4xl font-medium text-gray-800 dark:text-white">
            <span className="block">{title}</span>
          </p>
          <p className="text-center mb-12 text-xl font-normal text-white dark:text-gray-200">
            <span className="block">{subtext}</span>
          </p>
        </h2>
        <div className="lg:mt-0 lg:flex-shrink-0">
          <div className="md:mt-12 sm:mt-12 inline-flex ">
            <Link to={route}>
              <button
                className="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
              >
                {button}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
export default SectionBgImgTxtBtn;
