import { Link } from "react-router-dom";
import { useState, useRef } from "react";

import RightMenu from "./layouts/right-menu/RightMenu";
import NavMenu from "./layouts/nav-menu/NavMenu";

import newlogo from "./layouts/newlogo.jpg";
import rightarrow from "./images/right-arrow.png";
import consultdoctor from "./layouts/nav-menu/consultdoctor.png";
import labs from "./layouts/nav-menu/labs.png";
import mydoctor from "./layouts/nav-menu/mydoctor.png";
import pharmacy from "./layouts/nav-menu/pharmacy.png";
import { fetchTitle } from "../redux/actions/ProductActions";
import { useDispatch } from 'react-redux';

function NewTopNavBar() {
  const [showMenu, setShowMenu] = useState(false);

  const dispatch = useDispatch();
  const myContainer = useRef();

  const changeState = () => {
    const newData = myContainer;
    dispatch(fetchTitle(newData));
  };

  return (
    <>
      <div className="md:flex sm:flex">
        <div className="flex">
          <div className="block sm:hidden mt-6 ml-4">
            <button
              className="flex items-center px-3 py-2 border rounded text-gray-600 border-gray-600"
              onClick={() => setShowMenu(!showMenu)}
            >
              <svg
                className="h-3 w-3"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>
          <Link to="/">
            <img
              className="h-20 w-60 md:h-28 md:w-80 sm:h-20 sm:w-60"
              src={newlogo}
              alt="Logo"
            />
          </Link>
        </div>
        {showMenu && (
          <div
            className={`transform top-0 left-0 w-64 bg-white fixed h-full w-5/6 overflow-auto ease-in-out transition-all duration-300 z-30 absolute"
          ${showMenu ? "translate-x-0" : "-translate-x-full"}`}
          >
            <div className="flex p-4">
              <div className="block sm:hidden mt-5">
                <button
                  className="flex items-center px-3 py-2 border rounded text-gray-600 border-gray-600"
                  onClick={() => setShowMenu(!showMenu)}
                >
                  <svg
                    className="h-3 w-3"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                  </svg>
                </button>
              </div>
              <Link to="/">
                <img
                  className="mt-1 h-16 w-52 md:h-28 md:w-80 sm:h-20 sm:w-60"
                  src={newlogo}
                  alt="Logo"
                  onClick={() => setShowMenu(!showMenu)}
                />
              </Link>
            </div>
            <div className="p-4 bg-indigo-900 h-full">
              <div className="space-y-2">
                <Link to="/doctor-consultation" className="flex gap-2">
                  <div className="bg-white rounded-full h-12 w-12 p-2 ">
                    <img
                      src={consultdoctor}
                      alt="consult doctor"
                      className="h-8 w-8"
                    />
                  </div>
                  <p
                    className="font-bold text-white py-2"
                    onClick={() => setShowMenu(!showMenu)}
                  >
                    Consult Doctor
                  </p>
                </Link>
                <Link to="/labs" className="flex gap-2">
                  <div className="bg-white rounded-full h-12 w-12 p-2 ">
                    <img src={labs} alt="labs" className="h-8 w-8" />
                  </div>
                  <p
                    className="font-bold text-white py-2"
                    onClick={() => setShowMenu(!showMenu)}
                  >
                    LabTest & Packages
                  </p>
                </Link>
                <Link to="/pharmacy" className="flex gap-2">
                  <div className="bg-white rounded-full h-12 w-12 p-2 ">
                    <img src={pharmacy} alt="pharmacy" className="h-8 w-8" />
                  </div>
                  <p
                    className="font-bold text-white py-2"
                    onClick={() => setShowMenu(!showMenu)}
                  >
                    Pharmacy
                  </p>
                </Link>
                <Link to="/home-healthcare" className="flex gap-2">
                  <div className="bg-white rounded-full h-12 w-12 p-2 ">
                    <img
                      src={mydoctor}
                      alt="home healthcare"
                      className="h-8 w-8"
                    />
                  </div>

                  {/* <img src={rightarrow} className="h-7 w-7 mt-2" /> */}
                  <p
                    className="font-bold text-white py-2"
                    onClick={() => setShowMenu(!showMenu)}
                  >
                    Home Health Care
                  </p>
                </Link>
              </div>
              <div className="mt-4 ml-2">
                <Link to="/aboutus" className="flex gap-2">
                  <img
                    src={rightarrow}
                    alt="aboutus"
                    className="h-7 w-7 mt-2"
                  />
                  <p
                    className="font-bold text-white py-2"
                    onClick={() => setShowMenu(!showMenu)}
                  >
                    About Us
                  </p>
                </Link>
                <Link to="/contactus" className="flex gap-2" onClick={changeState}>
                  <img
                    src={rightarrow}
                    alt="contactus"
                    className="h-7 w-7 mt-2"
                  />
                  <p
                    className="font-bold text-white py-2"
                    onClick={() => setShowMenu(!showMenu)}
                  >
                    Contact Us
                  </p>
                </Link>
              </div>
            </div>
          </div>
        )}

        <NavMenu />

        <div className="hidden sm:block">
          <RightMenu />
        </div>
      </div>
    </>
  );
}
export default NewTopNavBar;
