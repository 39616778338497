import Footer from "./layouts/Footer";

import NewTopNavBar from "./NewTopNavBar";

function Cancellation() {
  return (
    <>
      {/* <TopNavBar /> */}
      <NewTopNavBar />
      <div className="max-w-7xl mx-auto px-4 bg-transparent-200 sm:px-6 lg:px-4 py-12">
        <p className="text-4xl font-bold text-black text-left">
          Cancellation & Refund Policy
        </p>
        <p className="text-black mt-10 font-medium text-lg">
          Cancellation and Refund Policies
        </p>
        <ul className="list-disc p-2 sm:p-0 sm:ml-20 mt-2">
          <li>
            <p className="text-black font-normal">
              If the customer cancels before the delivery of the service by
              through customer portal online or at the call centre, 100% refund
              will be initiated.
            </p>
          </li>
          <li>
            <p className="text-black font-normal">
              If CallMediLife initiates cancellation/rescheduling before
              delivery and the customer refuses the same, 100% refund will be
              initiated. In case, the payment is not made, there will be no
              refund.
            </p>
          </li>
          <li>
            <p className="text-black font-normal">
              If the customer cancels the service after the delivery has
              commenced, CallMediLife will stop the service and initiate full
              100% refund of the payment made.
            </p>
          </li>
        </ul>
        <p className="text-black font-normal">
          Note: The cancellation should be backed with a valid reason.
        </p>
        <ul className="list-disc p-2 sm:p-0 sm:ml-20 mt-2">
          <li>
            <p className="text-black font-normal">
              If the customer is not satisfied with the service, refund of the
              payment will not be initiated. CallMediLife will offer the
              customer an option to repeat the service at its discretion.
            </p>
          </li>
          <li>
            <p className="text-black font-normal">
              If the customer makes an incorrect billing, the exceeding amount
              will be returned back. In case, the amount paid is lesser than the
              price, the customer will have to pay the remaining amount to
              CallMediLife.
            </p>
          </li>
        </ul>
      </div>
      <Footer />
    </>
  );
}

export default Cancellation;
