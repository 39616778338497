import { ActionTypes } from "../constants/ActionTypes";
const intialState = {
  labtests: [],
};

export const labtestsReducer = (state = intialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_LABTESTS:
      return { ...state, labtests: payload };
    default:
      return state;
  }
};