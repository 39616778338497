function ProgressBar() {
  return (
    <div className="bg-gray-200 dark:bg-gray-800 md:px-24 sm:px-24 px-0">
      <div className="flex items-center justify-between w-full mx-auto py-12 px-4 sm:px-6 md:py-16 md:px-8 z-20">
        <div className="text-center">
          <p className="text-lg font-medium text-gray-800 dark:text-white">
            20,00,000+
          </p>
          <p className="text-sm font-normal text-gray-800 dark:text-white">
            Happy Users
          </p>
        </div>
        <div className="text-center">
          <p className="text-lg font-medium text-gray-800 dark:text-white">
            20,000+
          </p>
          <p className="text-sm font-normal text-gray-800 dark:text-white">
            Verifies Doctors
          </p>
        </div>
        <div className="text-center">
          <p className="text-lg font-medium text-gray-800 dark:text-white">
            25+
          </p>
          <p className="text-sm font-normal text-gray-800 dark:text-white">
            Specialities
          </p>
        </div>
        <div className="text-center">
          <p className="text-lg font-medium text-gray-800 dark:text-white">
            4.5/5
          </p>
          <p className="text-sm font-normal text-gray-800 dark:text-white">
            App Rating
          </p>
        </div>
      </div>
    </div>
  );
}
export default ProgressBar;
