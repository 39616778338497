import axios from "axios";
import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import ValidationError from "./ValidationError";

function BookHealthCare() {
  const [selectedOption, setSelects] = useState();

  const pageTitle = useSelector((state) => state.allTitles.title);

  const [values, setValues] = useState({
    name: "",
    mobile_number: "",
    address: "",
    city: "",
    email: "",
  });

  const [validation, setValidation] = useState({
    status: "success",
    message: "",
  });

  const set = (name) => {
    return ({ target: { value } }) => {
      setValues((oldValues) => ({ ...oldValues, [name]: value }));
    };
  };

  const [showForm, setShowForm] = useState(true);

  const submitForm = async (event) => {
    event.preventDefault();
    axios
      .post(process.env.REACT_APP_API_URL + "/api/sendemail/send", {
        ...values,
        pageTitle,
        selectedOption,
      })
      .then((res) => {
        if (res.data.status === "error") {
          setValidation(res.data);
        } else {
          setValues({
            name: "",
            mobile_number: "",
            address: "",
            city: "",
            email: "",
          });
          setShowForm(false);
        }
      })
      .catch((err) => {
        throw new Error("Request failed:" + err.message);
      });
  };

  return (
    <>
      <div
        className={`bg-white p-10 rounded-lg shadow-lg mt-20 ${
          showForm ? "invisible" : "visible"
        }`}
      >
        <p className={`text-red-600 font-medium text-xl text-center p-6`}>
          Thank you for your request.<br></br> Our team will get back to you
          soon.
        </p>
      </div>
      <div className="flex justify-center items-center md:-mt-60 sm:-mt-64 -mt-80 ">
        <div className="">
          <form
            className={`bg-white p-10 rounded-lg shadow-lg min-w-full ${
              showForm ? "" : "invisible"
            }`}
            onSubmit={submitForm}
          >
            <h1 className="text-center text-2xl mb-6 text-indigo-900 font-bold font-sans">
              Book Home Health Care Services from the comfort of your home
            </h1>
            <div></div>
            {pageTitle.length > 0 ? (
              <div>
                <label className="text-indigo-900 font-semibold block my-3 text-md">
                  Selected Option: &nbsp;
                  <span className="text-black font-semibold text-md bg-green-400 rounded p-1">
                    {pageTitle}
                  </span>
                </label>
              </div>
            ) : (
              <div>
                <label className="text-indigo-900 font-semibold block my-3 text-md">
                  Select Option:
                </label>
                <div className="mt-2 flex gap-6">
                  <select
                    value={selectedOption}
                    className="w-full bg-gray-200 px-4 py-2 rounded-lg border border-gray-400 focus:border-indigo-400 focus:outline-none "
                    onChange={(e) => setSelects(e.target.value)}
                  >
                    <option disabled selected="selected">
                      Select Option
                    </option>
                    <option>Consult Doctor</option>
                    <option>Home Health Care</option>
                    <option>Pharmacy</option>
                    <option>LabTest & Packages</option>
                  </select>
                </div>
              </div>
            )}
            <div>
              <label className="text-indigo-900 font-semibold block my-3 text-md">
                Name:
              </label>
              <input
                className={`w-full bg-gray-100 px-4 py-2 rounded-lg border border-gray-300 focus:border-indigo-400 focus:outline-none ${
                  validation.message.name ? `border-red-500` : `border-gray-400`
                }`}
                type="text"
                placeholder="Please Write Your Name"
                pattern="[A-Za-z\s]{1,32}"
                value={values.name}
                onChange={set("name")}
              />
            </div>
            {validation.status === "error" && (
              <ValidationError error={validation.message.name} />
            )}

            <div>
              <label className="text-indigo-900 font-semibold block my-3 text-md">
                Mobile No:
              </label>
              <input
                className={`w-full bg-gray-100 px-4 py-2 rounded-lg border border-gray-300 focus:border-indigo-400 focus:outline-none ${
                  validation.message.mobile_number
                    ? `border-red-500`
                    : `border-gray-400`
                }`}
                type="number"
                min="7000000000"
                max="9999999999"
                pattern="[789][0-9]{9}"
                placeholder="Please Write Your Mobile No"
                value={values.mobile_number}
                onChange={set("mobile_number")}
              />
            </div>
            {validation.status === "error" && (
              <ValidationError error={validation.message.mobile_number} />
            )}

            <div>
              <label className="text-indigo-900 font-semibold block my-3 text-md">
                Address:
              </label>
              <textarea
                className={`w-full bg-gray-100 px-4 py-2 rounded-lg border border-gray-300 focus:border-indigo-400 focus:outline-none ${
                  validation.message.address
                    ? `border-red-500`
                    : `border-gray-400`
                }`}
                placeholder="Please Write Your Address"
                value={values.address}
                onChange={set("address")}
              ></textarea>
            </div>
            {validation.status === "error" && (
              <ValidationError error={validation.message.address} />
            )}

            <div>
              <label className="text-indigo-900 font-semibold block my-3 text-md">
                City:
              </label>
              <input
                className={`w-full bg-gray-100 px-4 py-2 rounded-lg border border-gray-300 focus:border-indigo-400 focus:outline-none ${
                  validation.message.city ? `border-red-500` : `border-gray-400`
                }`}
                type="text"
                placeholder="Please Write Your City"
                pattern="[A-Za-z]{1,32}"
                value={values.city}
                onChange={set("city")}
              />
            </div>
            {validation.status === "error" && (
              <ValidationError error={validation.message.city} />
            )}

            <div>
              <label className="text-indigo-900 font-semibold block my-3 text-md">
                Email Id:
              </label>
              <input
                className={`w-full bg-gray-100 px-4 py-2 rounded-lg border border-gray-300 focus:border-indigo-400 focus:outline-none ${
                  validation.message.email
                    ? `border-red-500`
                    : `border-gray-400`
                }`}
                type="email"
                placeholder="Please Write Your Email Id"
                value={values.email}
                onChange={set("email")}
              />
            </div>
            {validation.status === "error" && (
              <ValidationError error={validation.message.email} />
            )}

            <div className="flex justify-center items-center">
              <button
                type="submit"
                className="w-1/2 mt-6 bg-indigo-900 rounded-full px-4 py-2 text-lg text-white tracking-wide font-semibold font-sans"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default BookHealthCare;
