import { Link, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useRef } from "react";
import { fetchTitle } from "../redux/actions/ProductActions";

import Footer from "./layouts/Footer";
import NewTopNavBar from "./NewTopNavBar";

import includetests from "./images/includetests.jpg";
import dropdown from "./images/dropdown.jpg";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { setLabtestgroups } from "../redux/actions/LabtestgroupActions";

function BasicPackage() {
  const dispatch = useDispatch();
  const myContainer = useRef(null);

  const changeState = () => {
    const newData = myContainer.current.innerText;

    dispatch(fetchTitle(newData));
  };

  const [packagesName, setPackagesName] = useState([]);
  const labtestgroups = useSelector(
    (state) => state.allLabtestgroups.labtestgroups
  );
  const { id } = useParams();
  const [toggleLabtest, setToggleLabtest] = useState();

  useEffect(() => {
    const fetchLabtestgroups = async () => {
      try {
        const res = await axios.get(
          process.env.REACT_APP_API_URL + `/api/test-groups/` + id
        );
        dispatch(setLabtestgroups(res.data.package.labtest_groups));
      } catch (err) {
        console.log("Err", err);
      }
    };
    fetchLabtestgroups();
  }, [dispatch, id]);

  useEffect(() => {
    const fetchDescriptions = async () => {
      try {
        const res = await axios.get(
          process.env.REACT_APP_API_URL + `/api/description/` + id
        );
        setPackagesName(res.data.packages);
      } catch (err) {
        console.log("Err", err);
      }
    };
    fetchDescriptions();
  }, [id]);

  return (
    <>
      <NewTopNavBar />
      <div className="max-w-7xl mx-auto px-4 bg-transparent-200 sm:px-6 lg:px-4 py-12">
        <div className="bg-white dark:bg-gray-800 overflow-hidden relative flex">
          <div className="">
            <img
              alt="package"
              src={includetests}
              className="absolute h-full w-full lg:block top-0 "
            />
          </div>
          <div className="text-start py-12 px-4 sm:px-6 lg:py-16 lg:px-8 z-20">
            <h2 className="text-3xl font-extrabold text-black dark:text-white sm:text-4xl">
              <p className="text-4xl font-medium dark:text-white text-gray-800">
                <span className="block" ref={myContainer}>
                  {packagesName.name}
                </span>
              </p>
              <p className="text-left mt-6 text-lg font-normal text-gray-800 dark:text-white">
                <span className="block">
                  ₹{packagesName.sellingPrice}/- 60% Off{" "}
                </span>
              </p>
            </h2>
            <div className="lg:mt-0 lg:flex-shrink-0">
              <div className="mt-2 inline-flex ">
                <Link to="/labs">
                  <button
                    className="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                  >
                    Back
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-10">
          <p className="text-xl font-bold text-black">About the Package</p>
          <div className="mt-5 flex flex-col md:flex-row sm:flex-row sm:mt-0">
            <p className="text-lg font-normal text-black ">
              Basic health check package which helps in the overall assessment
              of all the organs and systems of the body such as Heart, Liver,
              Kidneys, Thyroid, Urinary track infections, Diabetes, etc....
            </p>
            <Link to="/contactus">
              <button
                className="w-52 h-12 mt-3 sm:mt-1 md:mt-0 text-lg font-medium rounded-xl text-white bg-red-500 hover:bg-red-700"
                onClick={changeState}
              >
                Book Now
              </button>
            </Link>
            {/* 
              <button
                className="w-52 h-12 text-lg font-medium rounded-xl text-white bg-red-500 hover:bg-red-700"
                onClick={changeState}
              >
                Book Now
              </button> */}
          </div>
        </div>
        <div className="mt-10">
          <p className="text-xl font-bold text-black">Prerequisites</p>
          <p className="text-lg font-normal text-black mt-5">
            An early morning specimen is preferred, Minimum 8 - 10 hours Fasting
            is mandatory Plain Water is acceptable
          </p>
        </div>
        <div className="flex mt-10">
          <p className="text-xl font-bold text-black" key={id}>
            {packagesName.description}
          </p>
          <p className="text-lg font-normal text-black pl-2">in this package</p>
        </div>
        <div className="grid md:grid-cols-2 sm:grid-cols-1 md:gap-4 sm:gap-0">
          {labtestgroups.map((labtest_groups) => {
            return (
              <div className="md:mt-5 sm:mt-3 mt-3" key={labtest_groups.id}>
                <div>
                  <button
                    className="w-full md:h-16 h-16 sm:text-lg text-md font-medium rounded-lg shadow border-2 text-black p-3"
                    onClick={() =>
                      setToggleLabtest("labtest-group-" + labtest_groups.id)
                    }
                  >
                    <div className="flex justify-between">
                      <p className="ml-0 md:ml-3 sm:ml-2">
                        {labtest_groups.name} - {labtest_groups.description}
                      </p>
                      <img
                        src={dropdown}
                        className="mt-1 w-5 h-5"
                        alt="arrow"
                      />
                    </div>
                  </button>
                  <>
                    <p
                      className={`w-full text-base font-normal rounded-lg shadow border-2 text-gray-700 p-3 ${
                        toggleLabtest === "labtest-group-" + labtest_groups.id
                          ? ""
                          : "hidden"
                      }`}
                    >
                      {labtest_groups.labtests.map((labtest) => {
                        return (
                          <ul className="list-disc">
                            <li className="ml-5 mt-1" key={labtest.labtest_id}>
                              {labtest.name}
                            </li>
                          </ul>
                        );
                      })}
                    </p>
                  </>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default BasicPackage;
