function SectionImgText({ image, text, filter }) {
  return (
    <div className="overflow-hidden flex">
      <div>
        <div className={`shadow-lg mb-5 rounded-xl bg-indigo-900 md:w-60 sm:w-48 w-60 h-auto`}>
          <div className={`rounded-t-xl ${filter}`}>
            <img src={image} alt={text} className="md:w-60 w-60 sm:w-48 h-40" />
          </div>
          <div className="text-center py-4">
            <p className="text-white font-bold md:text-lg md:font-medium sm:text-medium sm:font-semibold">{text}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default SectionImgText;