function Profile({ image, description, name, designation }) {
  return (
    <div className="bg-white dark:bg-gray-800 md:w-1/3 rounded-lg p-4 mb-6 shadow sm:inline-block">
      <div className="flex md:flex items-start text-left">
        <div className="flex-shrink-0">
          <div className="inline-block relative">
            <a href="#profile" className="block relative">
              <img
                alt={name}
                src={image}
                className="sm:rounded-full md:rounded-full rounded-md mx-auto h-28 w-28 object-cover md:h-20 md:w-20 sm:h-10 sm:w-10 "
              />
            </a>
          </div>
        </div>
        <div className="ml-6">
          <p className="mt-1 dark:text-white">{description}</p>
          <div className="mt-4">
            <p className="text-right items-baseline">
              <span className="text-gray-600 dark:text-gray-200 font-bold">
                {name}
              </span>
            </p>
            <p className="text-right items-baseline">
              <span className="text-gray-500 dark:text-gray-300  ml-2 text-sm">
                {designation}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Profile;
