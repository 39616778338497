import { Link } from "react-router-dom";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { fetchTitle } from "../redux/actions/ProductActions";

function CircleImgCardBtn({ image, text, button, classes, route }) {
  const dispatch = useDispatch();
  const myContainer = useRef(null);

  const changeState = () => {
    const newData = myContainer.current.innerText;

    dispatch(fetchTitle(newData));
  };

  return (
    <div className="bg-gray-100 rounded-2xl rounded-shadow-lg sm:w-1/2 flex md:p-7 sm:p-4 p-4">
      <div className="w-1/3">
        <img
          alt={text}
          src={image}
          className="mx-auto object-cover rounded-full md:h-40 md:w-40 "
        />
      </div>
      <div className="w-2/3 px-9">
        <h6
          className={`md:text-2xl font-medium text-gray-800 dark:text-white ${classes}`}
          ref={myContainer}
        >
          {text}
        </h6>
        <div className="md:pl-36 md:py-8 sm:py-5">
          <Link to={route}>
            <button
              type="button"
              className="w-40 h-10 sm:w-36 sm:h-10 md:w-44 md:h-12 block md:text-lg md:font-thin rounded-full text-white bg-red-500 hover:bg-red-700"
              onClick={changeState}
            >
              {button}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
export default CircleImgCardBtn;
