import Footer from "./layouts/Footer";

import arrow from "./images/arrow.png";
import NewTopNavBar from "./NewTopNavBar";

function Services() {
  return (
    <>
      <NewTopNavBar />
      <div className="max-w-7xl mx-auto px-4 bg-transparent-200 sm:px-6 lg:px-4 py-12">
        <p className="text-3xl font-semibold text-black text-left">
          CallMediLife Services
        </p>
        <div className="bg-indigo-900 rounded-full w-full my-8">
          <p className="text-white text-3xl font-semibold text-center p-2">
            CORPORATE SERVICES
          </p>
        </div>
        <div>
          <div className="flex gap-2">
            <img src={arrow} className="w-4 h-4 mt-2" alt="arrow" />
            <p className="font-semibold text-black text-xl">
              Customised Wellness Program
            </p>
          </div>
          <p className="ml-6">
            We design, customise and deliver end-to-end wellness programs for
            many of our large corporate clients, which include:
          </p>
          <ul className="list-disc ml-14">
            <li>
              Review, assess and understand the health needs of their employees,
            </li>
            <li>
              Create and manage wellness packages and programmes to cater to the
              identified health needs,
            </li>
            <li>
              Design and implement collaborative and branding strategies for the
              corporate wellness initiatives of the organisation
            </li>
            <li>
              Providing access to its high quality network of medical
              professionals and facilities at discounted rates and packages
              including but not limited to its widespread network of onsite
              camps, diagnostics, pathology, specialists, counsellors etc.
            </li>
          </ul>
        </div>
        <div className="mt-6">
          <div className="flex gap-2">
            <img src={arrow} className="w-4 h-4 mt-2" alt="arrow" />
            <p className="font-semibold text-black text-xl">
              Access to Primary
            </p>
          </div>
          <p className="ml-6">
            Assured access to our network of primary health care centres which
            comprises of General Physician, Dentist, Cardiologist or any other
            specialists for the day-to-day needs of employees and for their
            loved ones
          </p>
        </div>
        <div className="mt-6">
          <div className="flex gap-2">
            <img src={arrow} className="w-4 h-4 mt-2" alt="arrow" />
            <p className="font-semibold text-black text-xl">
              Health Risk Management
            </p>
          </div>
          <p className="ml-6">
            AI (Artificial Intelligence) based Health Risk Assessment tool for
            assessing the health status/risk of an individual which assesses
            near accurate health needs of an individual; highly customizable as
            per the requirements of a corporate.
          </p>
        </div>
        <div className="mt-6">
          <div className="flex gap-2">
            <img src={arrow} className="w-4 h-4 mt-2" alt="arrow" />
            <p className="font-semibold text-black text-xl">
              Employee Assistance Program
            </p>
          </div>
          <p className="ml-6">
            Employee Assistance Programs are designed to help people understand
            and overcome their personal problems and thus improve their overall
            productivity and work efficiency. We offer Confidential Employee
            Assistance Programs on a short term and long term basis depending on
            the employee needs. These include counselling services and other
            services based on the employee needs based on the assessment of the
            personal or professional problems that affect their work
            performance.
          </p>
        </div>
        <div className="mt-6">
          <div className="flex gap-2">
            <img src={arrow} className="w-4 h-4 mt-2" alt="arrow" />
            <p className="font-semibold text-black text-xl">
              Annual Health Checks
            </p>
          </div>
          <p className="ml-6">
            Our highly customised employee health check programs for the
            employees and their families focus on early detection and prevention
            of ailments thus providing them a better health and improved loyalty
            towards their employer
          </p>
        </div>
        <div className="bg-indigo-900 rounded-full w-full mt-14 mb-8">
          <p className="text-white text-3xl font-semibold text-center p-2">
            INSURANCE SERVICES
          </p>
        </div>
        <div>
          <div className="flex gap-2">
            <img src={arrow} className="w-4 h-4 mt-2" alt="arrow" />
            <p className="font-semibold text-black text-xl">
              Pre-Policy Health Checks
            </p>
          </div>
          <p className="ml-6">
            We undertake pre-policy screening of insurance applicant of various
            insurance companies and schedule it as per the norms stated by the
            respective companies. We cater to a majority of the insurance
            companies in India and help them reduce their exposure by assessing
            the medical risk of their potential customers. Our unique market
            offerings include:
          </p>
          <p className="ml-6 mt-6">- A-class graded medical network</p>
          <p className="ml-6">
            - Superior customer experience through our network of professional
            team of medical service providers
          </p>
          <p className="ml-6">
            - Timely online reports backed by our superior technology
          </p>
          <p className="ml-6">
            - 24/7 call center facility and exceptional customer service and
            mobile application that enables seamless access to your customers
            and agents to schedule their medical appointments without any
            hassles
          </p>
        </div>
        <div className="mt-6">
          <div className="flex gap-2">
            <img src={arrow} className="w-4 h-4 mt-2" alt="arrow" />
            <p className="font-semibold text-black text-xl">
              Nationwide OPD (Out Patient) Network
            </p>
          </div>
          <p className="ml-6">
            With an ever growing network of health care providers across all
            major cities in the country helps us offer your employees
            uninterrupted and guaranteed access to primary health care centres
            for their regular and speciality OPD needs.
          </p>
        </div>
        <div className="mt-6">
          <div className="flex gap-2">
            <img src={arrow} className="w-4 h-4 mt-2" alt="arrow" />
            <p className="font-semibold text-black text-xl">
              Customised and Specialised Networks
            </p>
          </div>
          <p className="ml-6">
            Basis specific employer needs and requirements, we also possess the
            expertise of creating speciality networks e.g. Opthal network,
            Dental network, Home Service network, Dietician network, Counsellor
            Network as well as Diabetic Care network for our clients. So in case
            you are looking for a similar service, your search ends with
            CallMediLife.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Services;
