function RoundedBackgroundImgtxt({ image, text }) {
  return (
    <div className="grid justify-items-center">
      <div className="rounded-full bg-gray-100 w-24 h-24 p-7 ">
        <img className="rounded w-20 h-12 m-auto" src={image} alt={text} />
      </div>
      <div className="text-center mt-4">
        <p className="text-base text-black font-normal mb-2">{text}</p>
      </div>
    </div>
  );
}
export default RoundedBackgroundImgtxt;
