import React from "react";
import { useState } from "react";
import axios from "axios";

import pharmacy from "../pages/pharmacy/images/pharmacy.png";
import ValidationError from "./ValidationError";

import greentick from "./images/greentick.png";

function UploadPrescription() {
  const [showModal, setShowModal] = React.useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const [sendOtp, setSendOtp] = useState(true);
  const [showVerify, setShowVerify] = useState(true);
  const [showtick, setShowTick] = useState(true);
  const [submitButton, setSubmitButton] = useState(true);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isSelected, setIsSelected] = useState();

  const [phoneNumber, setPhoneNumber] = useState(null);
  const [otpSession, setOtpSeession] = React.useState(false);
  const [otp, setOtp] = useState(null);
  const [thankyouMessage, setThankyouMessage] = useState(false);

  // allowedFiles = [".png", ".jpg", ".pdf", ".jpeg"];

  // const file_upload = (value) => {
  //   if (!allowedFiles(value)) {
  //     return `Upload only jpg, jpeg, png, pdf files..`
  //   }
  // };

  const changeHandler = (event) => {
    setUploadedFile(event.target.files[0]);
    setIsSelected(true);
  };

  const [validation, setValidation] = useState({
    status: "success",
    message: "",
  });

  const [data, setData] = useState({
    name: "",
    email: "",
    address: "",
    pincode: "",
    upload_file: "",
  });

  const set = (name) => {
    return ({ target: { value } }) => {
      setData((oldData) => ({ ...oldData, [name]: value }));
    };
  };

  const generateOtp = async (e) => {
    e.preventDefault();

    setValidation({
      status: "success",
      message: "",
    });

    axios
      .post(process.env.REACT_APP_API_URL + "/api/generate-otp", {
        phone: phoneNumber,
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "error") {
          setValidation(res.data);
        } else {
          setOtpSeession(res.data.session_id);
          setShowVerify(false);
          setSendOtp(false);
        }
      });
  };

  const verifyOtp = async (e) => {
    e.preventDefault();
    setValidation({
      status: "success",
      message: "",
    });
    axios
      .post(process.env.REACT_APP_API_URL + "/api/verify-otp", {
        otp: otp,
        session_id: otpSession,
      })
      .then((res) => {
        if (res.data.status === "error") {
          setValidation(res.data);
        } else {
          setSubmitButton(false);
          setShowVerify(true);
          setShowTick(false);
        }
      });
  };

  const onUpload = async (e) => {
    e.preventDefault();
    setValidation({
      status: "success",
      message: "",
    });
    const formData = new FormData();
    formData.append("upload_file", uploadedFile);
    formData.append("name", data.name);
    formData.append("email", data.email);
    formData.append("address", data.address);
    formData.append("pincode", data.pincode);
    formData.append("phone", phoneNumber);

    try {
      const res = await axios({
        method: "post",
        url: process.env.REACT_APP_API_URL + "/api/upload-prescription/upload",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (res.data.status === "error") {
        console.log(res.data);
        setValidation(res.data);
      } else {
        setData({
          name: "",
          upload_file: "",
          email: "",
          address: "",
          pincode: "",
        });
        setShowModal(false);
        thankyouText();
        setThankyouMessage(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const thankyouText = () => {
    setTimeout(() => {
      setThankyouMessage(false);
    }, 3000);
  };

  return (
    <>
      <div className="bg-white dark:bg-gray-800 overflow-hidden relative flex">
        <div className="">
          <img
            alt="heading"
            src={pharmacy}
            className="absolute h-full w-full lg:block top-0"
          />
        </div>
        <div className="text-start py-12 px-3 sm:px-6 lg:py-16 lg:px-8 z-20">
          <h2 className="text-3xl font-extrabold text-black dark:text-white sm:text-4xl">
            <p className="text-4xl font-medium dark:text-white text-gray-800">
              <span className="block">Pharmacy</span>
            </p>
            <p className="text-left mt-6 text-lg font-normal text-gray-800 dark:text-white">
              <span className="block">
                Delivery in almost all cities all over India.
              </span>
            </p>
            <p className="text-left mb-6 text-lg font-normal text-gray-800 dark:text-white">
              <span className="block">
                Exhaustive information about medicine written by verified
                medical experts.
              </span>
            </p>
          </h2>
          <div className="lg:mt-0 lg:flex-shrink-0">
            <div className="mt-2 inline-flex ">
              <button
                className="bg-red-500 text-white active:bg-red-700 font-bold uppercase text-sm px-6 py-3 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => setUploadModal(true)}
              >
                Upload Prescription
              </button>
            </div>
          </div>
        </div>
      </div>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="border-2 border-gray-200 rounded-2xl px-3 py-6 sm:p-6 md:p-6 mt-8 bg-white">
              <p className="font-semibold text-2xl text-center mb-2">
                Upload Prescription
              </p>
              <div className="flex"></div>
              <div className="mt-4">
                <label className="text-red-500 font-semibold block ml-1 text-md">
                  Name:
                </label>
                <input
                  type="text"
                  placeholder="Enter Your Name Here"
                  name="name"
                  className="sm:w-80 w-72 border-gray-900 text-black rounded-lg border-0 shadow outline-none px-2 py-1 "
                  value={data.name}
                  onChange={set("name")}
                />
                {validation.status === "error" && (
                  <ValidationError error={validation.message.name} />
                )}
              </div>

              <div className="mt-4 md:flex sm:flex">
                <div className="flex-1">
                  <label className="text-red-500 font-semibold block ml-1 text-md">
                    Mobile No:
                  </label>
                  <div className="flex">
                    <input
                      type="text"
                      placeholder="Enter Your Moblie No. Here"
                      className="sm:w-52 w-64 text-black rounded-lg border-0 shadow outline-none px-2 py-1"
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                    <button
                      type="button"
                      className={`w-20 h-8 rounded-2xl bg-indigo-900 text-white ml-1 ${
                        sendOtp ? "visible" : "invisible"
                      }`}
                      onClick={generateOtp}
                    >
                      Send OTP
                    </button>
                  </div>
                  {validation.status === "error" && (
                    <div className="text-yellow-500 font-medium text-center">
                      <ValidationError error={validation.message.phone} />
                    </div>
                  )}
                </div>
                <div className="flex-1 sm:mt-6 mt-3">
                  <input
                    type="text"
                    placeholder="Enter OTP Here"
                    onChange={(e) => {
                      setOtp(e.target.value);
                    }}
                    name="otp"
                    className={`w-40 text-black rounded-lg border-0 shadow outline-none px-2 py-1 sm:-ml-20 md:-ml-20 ${
                      showVerify ? "hidden" : " "
                    }`}
                  />
                  <button
                    type="button"
                    className={`w-20 h-8 rounded-xl bg-indigo-900 text-white ml-1 ${
                      showVerify ? "hidden" : " "
                    }`}
                    onClick={verifyOtp}
                  >
                    Verify
                  </button>
                  <img
                    src={greentick}
                    alt="verifed tick"
                    className={`sm:-ml-20 sm:-mt-0 -mt-10 ml-64 ${showtick ? "hidden" : " "}`}
                  />

                  {validation.status === "error" && (
                    <div className="text-red-500 font-medium text-center -ml-20">
                      <ValidationError error={validation.message.otp} />
                    </div>
                  )}
                </div>
              </div>
              <div className="sm:flex mt-4 gap-2">
                <div>
                  <label className="text-red-500 font-semibold block ml-1 text-md">
                    Email ID:
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Your Email ID Here"
                    name="email"
                    className="sm:w-52 w-72 text-black rounded-lg border-0 shadow outline-none px-2 py-1"
                    value={data.email}
                    onChange={set("email")}
                  />
                  {validation.status === "error" && (
                    <ValidationError error={validation.message.email} />
                  )}
                </div>

                <div>
                  <label className="mt-2 sm:mt-0 text-red-500 font-semibold block ml-1 text-md">
                    Pincode:
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Your Pincode Here"
                    name="pincode"
                    className="sm:w-52 w-72 text-black rounded-lg border-0 shadow outline-none px-2 py-1"
                    value={data.pincode}
                    onChange={set("pincode")}
                  />
                  {validation.status === "error" && (
                    <ValidationError error={validation.message.pincode} />
                  )}
                </div>
              </div>
              <div className="mt-4">
                <label className="text-red-500 font-semibold block ml-1 text-md">
                  Address:
                </label>
                <textarea
                  placeholder="Enter Your Address Here"
                  rows="3"
                  cols="50"
                  name="address"
                  className="w-72 sm:w-auto text-black rounded-lg border-0 shadow outline-none px-2 py-1"
                  value={data.address}
                  onChange={set("address")}
                />
                {validation.status === "error" && (
                  <ValidationError error={validation.message.address} />
                )}
              </div>

              <div className="text-center mt-6 space-x-6">
                <button
                  className={`w-32 h-10 rounded-full bg-red-500 text-white disabled:opacity-30 
                  ${submitButton ? "cursor-not-allowed" : " "}`}
                  disabled={submitButton}
                  onClick={onUpload}
                >
                  Submit
                </button>
                <button
                  className="w-32 h-10 rounded-full border-2 border-gray-500 bg-white text-gray-500"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      {uploadModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="border-2 border-gray-200 rounded-2xl md:p-6 p-4 mt-8 bg-white">
              <div className="max-w-md mx-auto rounded-lg overflow-hidden md:max-w-xl">
                <div className="md:flex">
                  <div className="w-full p-3">
                    <div className="relative border-dotted h-48 rounded-lg border-dashed border-2 border-red-500 bg-gray-100 flex justify-center items-center">
                      <div className="absolute">
                        <div className="flex flex-col items-center">
                          <i className="fa fa-folder-open fa-4x text-red-500"></i>
                          {isSelected ? (
                            <span className="block font-normal text-red-600">
                              {uploadedFile.name}
                            </span>
                          ) : (
                            <span className="block text-gray-400 font-normal">
                              Upload Prescription
                            </span>
                          )}
                          <span className="block text-gray-400 font-normal text-xs text-center">
                            (Upload only jpg, jpeg, png, pdf files.)
                          </span>
                        </div>
                      </div>
                      <input
                        type="file"
                        accept=".jpeg, .png, .pdf, .jpg"
                        className="h-full w-full opacity-0"
                        name="upload_file"
                        onChange={changeHandler}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="hidden border-2 border-dotted rounded-lg border-dashed p-5 mt-4 w-auto md:block sm:hidden">
                <p className="font-bold">How to Order through Prescription</p>
                <div className="flex gap-2 md:px-10 md:py-10 sm:px-10 sm:py-5">
                  <div>
                    <div className="rounded-full bg-red-500 w-10 h-10">
                      <p className="p-2 ml-1">01</p>
                    </div>
                  </div>
                  <div class="md:flex-grow border-t-4 sm:flex-shrink sm:w-20 border-red-500 border-dashed mt-4"></div>
                  <div>
                    <div className="rounded-full bg-red-500 w-10 h-10">
                      <p className="p-2 ml-1">02</p>
                    </div>
                  </div>
                  <div class="md:flex-grow sm:flex-shrink sm:w-20 border-t-4 border-red-500 border-dashed mt-4"></div>
                  <div>
                    <div className="rounded-full bg-red-500 w-10 h-10">
                      <p className="p-2 ml-1">03</p>
                    </div>
                  </div>
                  <div class="md:flex-grow border-t-4 sm:flex-shrink sm:w-20 border-red-500 border-dashed mt-4"></div>
                  <div>
                    <div className="rounded-full bg-red-500 w-10 h-10">
                      <p className="p-2 ml-1">04</p>
                    </div>
                  </div>
                </div>
                <div className="flex">
                  <p className="text-center text-base text-black font-medium md:mb-2 sm:mb-1 md:w-60 sm:w-32">
                    Upload prescription for a new order
                  </p>
                  <p className="text-center text-base text-black font-medium md:mb-2 sm:mb-1 md:w-60 sm:w-32">
                    Fill user information, delivery address and confirm your
                    order
                  </p>
                  <p className="text-center text-base text-black font-medium md:mb-2 sm:mb-1 md:w-60 sm:w-32 ml-2">
                    We will reach out to you regarding your order
                  </p>
                  <p className="text-center text-base text-black font-medium md:mb-2 sm:mb-1 md:w-60 sm:w-32">
                    Have our pharmacy partner(s) Deliver your medicines
                  </p>
                </div>
              </div>
              <div className="text-center mt-8 space-x-6">
                <button
                  className={`w-32 h-10 rounded-full bg-red-500 text-white disabled:opacity-30 
                  ${isSelected ? "" : "cursor-not-allowed"}`}
                  onClick={() => setShowModal(true) || setUploadModal(false)}
                  disabled={!isSelected}
                >
                  Next
                </button>
                <button
                  className="w-32 h-10 rounded-full border-2 border-gray-500 bg-white text-gray-500"
                  onClick={() => setUploadModal(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      {thankyouMessage ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="border-2 border-gray-200 rounded-2xl sm:p-6 md:p-6 p-4 mt-8 text-red-500 text-center bg-white">
              <div className="md:py-8 md:px-4 sm:py-7 sm:px-4 py-4 px-0">
                <span className="font-semibold text-xl">
                  Thank you for the request.
                  <br /> Our team will get back to you soon.
                </span>
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
export default UploadPrescription;
