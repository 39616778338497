import { Link } from "react-router-dom";

function CircleBackgroundImgTitle({ image, title, classes }) {
  return (
    <div className="">
      <div className="rounded-full bg-gray-200 md:w-36 md:h-36 md:p-7 sm:p-6 p-9">
        <Link to="/doctor-consultation">
          <img
            className={`rounded-full w-30 h-25 m-auto ${classes}`}
            src={image}
            alt={title}
          />
        </Link>
      </div>
      <div className="text-center">
        <p className="md:text-xl text-black font-normal mb-2">{title}</p>
      </div>
    </div>
  );
}
export default CircleBackgroundImgTitle;
