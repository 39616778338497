import Footer from "./layouts/Footer";

import PriceCardWithImg from "./PriceCardWithImg";
import SectionHeading from "./SectionHeading";

import arrow from "./images/arrow.png";
import NewTopNavBar from "./NewTopNavBar";
import React, { useEffect } from "react";
import { setPackages } from "../redux/actions/PackageActions";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";

function AllPackages() {
  const packages = useSelector((state) => state.allPackages.packages);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const res = await axios
          .get(process.env.REACT_APP_API_URL + "/api/packages")
          .then((cmlPackage) => cmlPackage.data.packages);
        dispatch(setPackages(res));
      } catch (err) {
        console.log("Err", err);
      }
    };
    fetchPackages();
  }, [dispatch]);

  return (
    <>
      {/* <TopNavBar /> */}
      <NewTopNavBar />

      <div className="max-w-7xl mx-auto px-4 bg-transparent-200 sm:px-6 lg:px-4 py-12">
        <div>
          <SectionHeading title="Health Checkup Packages" />
        </div>
        <div className="mt-8 grid md:grid-cols-3 sm:grid-cols-2 gap-4 justify-items-center">
        {packages.map((cmlpackage) => {
          const { id, name, description, image, sellingPrice, mrpPrice } = cmlpackage;
          return(
          <PriceCardWithImg
              key={ id }
              image={image}
              name={name}
              description={description}
              sellingPrice={sellingPrice}
              mrpPrice={mrpPrice}
              button="Book Now"
              route="/contactus"
              route1={`/package-lab-test-group/` +id}
          /> 
          );
        })}
        </div>
        <div className="mt-16">
          <p className="text-3xl font-medium tracking-wide text-gray-800 dark:text-white md:text-3xl">
            Booking Procedure:
          </p>
          <ul className="list-disc mt-3 ml-7">
            <li>
              Opt for a package of your choice and fill the necessary
              information in the form.
            </li>
            <li>
              A CallMediLife agent will contact you and make an appointment for
              you.
            </li>
            <li>
              A paramedical staff member will come to collect your blood sample
              at your home.
            </li>
            <li>
              Reports will be emailed in a soft copy within 48 hours. In case,
              you need a hard copy, it will be sent to your residence in 2-3
              business days.
            </li>
          </ul>
        </div>
        <div className="mt-6">
          <div className="flex gap-2">
            <img src={arrow} className="w-4 h-4 mt-2" alt="arrow" />
            <p className="font-semibold text-lg"> Why Choose CallMediLife?</p>
          </div>
          <p className="mt-1 ml-2">
            CallMediLife believes in putting out reports that are 100% accurate.
            Also, it has made its name in healthcare industry over the years.
          </p>
        </div>
        <div className="mt-4">
          <div className="flex gap-2">
            <img src={arrow} className="w-4 h-4 mt-2" alt="arrow" />
            <p className="font-semibold text-lg"> Free Sample Collection.</p>
          </div>
          <p className="mt-1 ml-2">
            Our certified Phlebotomists will collect samples from the comfort of
            your doorstep.
          </p>
        </div>
        <div className="mt-4">
          <div className="flex gap-2">
            <img src={arrow} className="w-4 h-4 mt-2" alt="arrow" />
            <p className="font-semibold text-xl"> Fully Automated Labs.</p>
          </div>
          <p className="mt-1 ml-2">
            Our test centers share e-reports within 24-48 hours of sample
            collection.
          </p>
        </div>
        <div className="mt-4">
          <div className="flex gap-2">
            <img src={arrow} className="w-4 h-4 mt-2" alt="arrow" />
            <p className="font-semibold text-xl">
              Accurate & Verified Reports.
            </p>
          </div>
          <p className="mt-1 ml-2">
            Our fully equipped Labs feature state-of-the art technologies for
            highest accuracy.
          </p>
        </div>
        <div className="mt-4">
          <div className="flex gap-2">
            <img src={arrow} className="w-4 h-4 mt-2" alt="arrow" />
            <p className="font-semibold text-xl">
              Offers and Affordable prices.
            </p>
          </div>
          <p className="mt-1 ml-2">
            We are giving the best discount on all health check-up packages.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default AllPackages;
