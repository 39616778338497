import Footer from "./layouts/Footer";

import NewTopNavBar from "./NewTopNavBar";

function Terms() {
  return (
    <>
      <NewTopNavBar />
      <div className="max-w-7xl mx-auto px-4 bg-transparent-200 sm:px-6 lg:px-4 py-12">
        <p className="text-4xl font-bold text-black text-left">
          Terms & Privacy policy
        </p>
        <p className="mt-10 font-medium text-lg">
          CALLMEDILIFE RESPECTS THE PRIVACY OF EACH CUSTOMER
        </p>
        <p className="text-black mt-3 font-normal">
          We, at CALLMEDILIFE, believe in protecting the personal information of
          our customers. The data collection, disclosure, and usage process is
          explained efficiently through our policy. Thus, ensuring total
          transparency in data protection. The personal information that is
          collected will be used as per described in the privacy policy.
        </p>
        <p className="text-black mt-3 font-normal">
          All the terms and phrases used in the Agreement will be as per the
          definitions established in this privacy policy, unless the context
          clearly indicates otherwise. E.g. "Data" describes any information
          (including Personal Information) provided to CALLMEDILIFE by a
          customer and service provider (Pathology Lab); and "Personal
          Information" refers to information about an identifiable customer,
          such as name, email address etc.
        </p>
        <p className="text-black mt-3 font-medium text-lg underline">
          DATA COLLECTION
        </p>
        <ol className="list-decimal p-2 sm:p-0">
          <li>
            <p className="text-black font-medium mt-2">Customer Registration</p>
          </li>
          <p className="text-black font-normal">
            Each customer gives access to specific data, including a login ID, a
            password, and an email address during registration. Our database
            stores the data to identify the customer during further visits.
            Also, the customers may be required to provide necessary billing
            information for online To ensure there is no online fraud happening,
            we automatically receive and record information on server logs from
            the customer's browser, including an IP address, CALLMEDILIFE cookie
            information, and the page requested. The websites that our customers
            access before and after visiting ours may or may not record their
            personal information, over which we don't exercise any control.
          </p>
        </ol>
        <p className="text-black mt-3 font-medium text-lg underline">
          DATA USAGE
        </p>
        <ol className="list-decimal p-2 sm:p-0">
          <li>
            <p className="text-black font-medium mt-2">
              Call, Messages and Updates
            </p>
          </li>
          <p className="text-black font-normal">
            We may SMS, call, or send email updates to the customers on their
            email accounts or phone numbers with regard to our services. This is
            usually done to ensure smooth delivery and procedures after the
            delivery services. Our new and existing customers will receive the
            promotional messages. However, they can voluntarily unsubscribe.
          </p>
          <li>
            <p className="text-black font-medium mt-2">Cookies</p>
          </li>
          <p className="text-black font-normal">
            In order to improve the user experience, we may use the customer's
            personal information. We may use cookies to manage and create a
            tailored website. Our diagnosis of problems is made easier by
            recording IP addresses. Thus, managing the website effectively.
          </p>
          <li>
            <p className="text-black font-medium mt-2">Targeted Content</p>
          </li>
          <p className="text-black font-normal">
            CALLMEDILIFE may put out adverts based on your searches and data
            while you're at it. Rest assured, we do not share the personal
            information of our data with any of the advertisers except for the
            time when the customer has given their consent through the pop up
            box on the ad. The websites that our customers access before and
            after visiting ours may or may not record their personal
            information, over which we don't exercise any control. Furthermore,
            the advertiser may store the customer's personal information if the
            customer is on their website.
          </p>
        </ol>
        <p className="text-black mt-3 font-medium text-lg underline">
          DATA DISCLOSURE
        </p>
        <ol className="list-decimal p-2 sm:p-0">
          <li>
            <p className="text-black font-medium mt-2">No Selling</p>
          </li>
          <p className="text-black font-normal">
            CALLMEDILIFE sticks to its privacy policy and refrain from sharing,
            selling, or distributing any personal information of the customer.
          </p>
          <li>
            <p className="text-black font-medium mt-2">Marketing Purposes</p>
          </li>
          <p className="text-black font-normal">
            CALLMEDILIFE may share general data statistics to advertisers for
            marketing purposes. However, it does not take the responsibility or
            gives any warranty for the security and privacy of third party or
            any other linked websites.
          </p>
          <li>
            <p className="text-black font-medium mt-2">
              Third Party Supplier/ Agents/ Affiliates 1. Customer Registration
            </p>
          </li>
          <p className="text-black font-normal">
            CALLMEDILIFE may share customer's data with a third party
            supplier/agent-affiliate to provide certain services to the
            customer. The data shared will be limited and not hinder the privacy
            of the customer.
          </p>
          <p className="text-black font-normal mt-2">
            We can also disclose data to designated third parties to resolve or
            investigate abuse complaints. Data can be released to assist in
            attempting to block an abusive customer or complain to their
            Internet Service Provider.
          </p>
          <li>
            <p className="text-black font-medium mt-2">Law Enforcement</p>
          </li>
          <p className="text-black font-normal">
            CALLMEDILIFE may disclose data if required by the law (e.g. by a
            subpoena or any other judicial or administrative order) to protect
            the safety of any individual or the general public, to prevent
            violation of the agreement as well as the rights of CALLMEDILIFE or
            any third party.
          </p>
          <li>
            <p className="text-black font-medium mt-2">Change of Ownership</p>
          </li>
          <p className="text-black font-normal">
            In case of a change of ownership (merger/ acquisition/ sale etc.),
            CALLMEDILIFE may reallocate the rights to data to the new owner.
            CALLMEDILIFE will send a notification before doing so to each
            Customer so that the customer may choose to modify/ delete their
            personal data as they deem fit.
          </p>
          <li>
            <p className="text-black font-medium mt-2">Employees</p>
          </li>
          <p className="text-black font-normal">
            CALLMEDILIFE will share data with its employees as per the need of
            the job.
          </p>
        </ol>
        <p className="text-black mt-3 font-medium text-lg underline">
          SECURITY OF DATA
        </p>
        <p className="text-black mt-3 font-normal">
          CALLMEDILIFE applies advanced security measures to protect the
          customer data repository. Data is never disclosed unless the customer
          gives their consent. The website uses a secure server environment with
          a robust firewall and other advanced security measures to prevent
          interference or access from intruders.In addition, the customers are
          advised to maintain the confidentiality of their login details.
        </p>
        <ol className="list-decimal p-2 sm:p-0">
          <li>
            <p className="text-black font-medium mt-2">
              Updating or Removing Data
            </p>
          </li>
          <p className="text-black font-normal">
            The customers can update their personal information through the
            login and even deactivate their account if need be.
          </p>
          <li>
            <p className="text-black font-medium mt-2">Unauthorized Use</p>
          </li>
          <p className="text-black font-normal">
            Any unauthorized use of CALLMEDILIFE information system is a
            violation of the agreement and certain laws, including the section
            86 of the ECT Act. Such violations will be subject to civil criminal
            penalties. In addition, to avoid any unauthorized use, we can
            intercept, block, filter, delete, and disclose any communication
            over our information system.
          </p>
        </ol>
        <p className="text-black mt-3 font-medium text-lg underline">
          SERVICE DELIVERY & PAYMENTS
        </p>
        <p className="text-black mt-3 font-normal">
          CALLMEDILIFE follows a certain set of rules and conditions to govern
          service delivery and Payments processing
        </p>
        <ol className="list-decimal p-2 sm:p-0">
          <li>
            <p className="text-black font-medium mt-2">
              Prices/Terms and Conditions of Payment:
            </p>
          </li>
          <ul className="list-disc p-2 sm:p-0 sm:ml-20">
            <li>
              <p className="text-black font-normal">
                The service package charges specified on the website exclude any
                additional charges imposed by the banks or portals. The customer
                is required to pay for all of it. In case of payment defaults
                (payment deadlines not met, online transaction denied, any other
                payment default), CALLMEDILIFE will automatically cancel the
                service request, with a notification sent to the customer.
              </p>
            </li>
            <li>
              <p className="text-black font-normal">
                Custom made packages are potentially susceptible to increase in
                price and quantity in a reasonable amount
              </p>
            </li>
          </ul>
          <li>
            <p className="text-black font-medium mt-2">Delivery Dates:</p>
          </li>
          <p className="text-black font-normal">
            CALLMEDILIFE adheres to the delivery time stipulated by the service
            provider. However, in case of hazards or delays in lab testing,
            these dates are subject to change.
          </p>
          <li>
            <p className="text-black font-medium mt-2">Passing of Risk:</p>
          </li>
          <p className="text-black font-normal">
            The lab providing the services will bear all risk of accidental
            loss, destruction or deterioration.
          </p>
          <li>
            <p className="text-black font-medium mt-2">Errors in Report:</p>
          </li>
          <ul className="list-disc p-2 sm:p-0 sm:ml-20">
            <li>
              <p className="text-black font-normal">
                Any error in the report should be reported within 7 days of the
                issue of the receipt. The errors should be notified. Failing to
                do so will lead to no compensation.
              </p>
            </li>
            <li>
              <p className="text-black font-normal">
                For loss or damage caused by delay due to ordinary negligence,
                we shall be liable for up to only 5% of the booking price agreed
                with us.
              </p>
            </li>
          </ul>
          <li>
            <p className="text-black font-medium mt-2">
              Applicable Laws and Judicial Forum:
            </p>
          </li>
          <ul className="list-disc p-2 sm:p-0 sm:ml-20">
            <li>
              <p className="text-black font-normal">
                Substantive laws of the Republic of India shall apply to all
                legal relationships and transactions established by this
                purchase agreement.
              </p>
            </li>
            <li>
              <p className="text-black font-normal">
                The judicial forum for both parties, including for bill of
                contract matters, is our principal place of business (registered
                office). If we become the plaintiff in litigation, then we are
                also entitled to bring an action in the jurisdiction of the
                customer's principal place of business (registered office).
              </p>
            </li>
          </ul>
        </ol>
        <p className="text-black mt-3 font-medium text-lg underline">
          REFUND & CANCELLATION POLICY DATA COLLECTION
        </p>
        <ol className="list-decimal p-2 sm:p-0">
          <li>
            <p className="text-black mt-3 font-normal">
              CALLMEDILIFE offers its clients the option to cancel the order
              anytime before the beginning of the service. The refund will be
              initiated fully. A service is deemed to start as soon as the
              sample collector leaves for sample collection to the respective
              client chosen address. A service request shall not be cancelled
              once the service has started.
            </p>
          </li>
          <li>
            <p className="text-black mt-3 font-normal">
              In case of delay in services from the service provider, customers
              can ask for a full refund. to
            </p>
          </li>
          <li>
            <p className="text-black mt-3 font-normal">
              If the customer isn't happy, refund of money will be initiated.
            </p>
          </li>
          <li>
            <p className="text-black mt-3 font-normal">
              In case of any issue with the reports, client can inform the same
              within 1 day of the publication of the reports.
            </p>
          </li>
          <li>
            <p className="text-black mt-3 font-normal">
              Any issues raised after 48 hours of reports publication will not
              be entertained since thesamples collected will not be viable and
              would require fresh sample collection. This would be treated as a
              new order and would be chargeable at normal rates
            </p>
          </li>

          <p className="text-black mt-3 font-medium">
            Refunds would be processed within 7-10 business days of a
            cancellation.
          </p>
        </ol>
        <p className="text-black mt-3 font-medium text-lg underline">
          General terms and conditions for coupon payments.
        </p>
        <p className="text-black mt-3 font-normal">
          CALLMEDILIFE issues coupons and discounts to its users to reward them
          for their relationship with CALLMEDILIFE. Below are the terms and
          conditions of using the coupons. CALLMEDILIFE reserves the right to
          change/update these terms and conditions at anytime.
        </p>
        <ol className="list-decimal p-2 sm:p-0">
          <li>
            <p className="text-black mt-3 font-normal">
              The coupons have to be applied at the time of checkout in order to
              avail the benefits.
            </p>
          </li>
          <li>
            <p className="text-black mt-3 font-normal">
              The coupon codes should be used before the validity expires. As
              the validity ends, the codes will be rendered ineffective.
            </p>
          </li>
          <li>
            <p className="text-black mt-3 font-normal">
              Coupons can be used only once and are user bound. Thus, the
              coupons are non transferable, unless specified.
            </p>
          </li>
          <li>
            <p className="text-black mt-3 font-normal">
              A single coupon can be redeemed at one time.
            </p>
          </li>
          <li>
            <p className="text-black mt-3 font-normal">
              Hard copy charges and sample collection charges if applicable will
              be applied on the net amount payable upon application of the
              coupon code, unless otherwise mentioned.
            </p>
          </li>
          <li>
            <p className="text-black mt-3 font-normal">
              The coupons can only be redeemed on the mobile app of
              CALLMEDILIFE.
            </p>
          </li>
          <li>
            <p className="text-black mt-3 font-normal">
              CALLMEDILIFE reserves the right to change the terms and conditions
              of any coupon code any time without prior intimation
            </p>
          </li>
        </ol>
        <p className="text-black mt-3 font-medium">
          CALLMEDILIFE issues booking coupons on first order of each user. Users
          can use these coupons to save on their future orders on CALLMEDILIFE.
          Below are the terms and conditions for these coupons:
        </p>
        <ol className="list-decimal p-2 sm:p-0">
          <li>
            <p className="text-black mt-3 font-normal">
              The first order coupons can be redeemed only on the first order
              with CALLMEDILIFE.
            </p>
          </li>
          <li>
            <p className="text-black mt-3 font-normal">
              Subsequently, users will get coupons equivalent to 10% of their
              first order value.
            </p>
          </li>
          <li>
            <p className="text-black mt-3 font-normal">
              Coupon equivalent to 10% of the first order value will be issued
              in the form of coupon codes of various denominations cumulatively
              amounting to 10% of order value. For e.g. if the value is Rs.1078
              then the customer will get 3 coupons, 2 of Rs.50 each and one
              coupon of Rs.8 taking the total to Rs.108 worth of coupons.
            </p>
          </li>
          <li>
            <p className="text-black mt-3 font-normal">
              Coupon will be issued in the form of codes of various
              denominations cumulatively amounting to Rs.200.
            </p>
          </li>
          <li>
            <p className="text-black mt-3 font-normal">
              The cart value should be kept at Rs. 500 minimum in order to
              redeem the first order coupons. 6. All the general coupons terms
              and conditions mentioned above are applicable on first order
              coupons.
            </p>
          </li>
          <li>
            <p className="text-black mt-3 font-normal">
              This offer is also applicable to users who have placed orders with
              CALLMEDILIFE in the past but have not received the first order
              coupons.
            </p>
          </li>
          <li>
            <p className="text-black mt-3 font-normal">
              The coupons can only be redeemed on the mobile app of
              CALLMEDILIFE.
            </p>
          </li>
          <li>
            <p className="text-black mt-3 font-normal">
              Coupons are user specific and are non-transferable.
            </p>
          </li>
          <li>
            <p className="text-black mt-3 font-normal">
              CALLMEDILIFE reserves the rights to change these conditions or
              withdraw the offer withoutany prior intimation.
            </p>
          </li>
        </ol>
        <p className="text-black mt-3 font-medium text-lg underline">
          Signup coupons terms and condition
        </p>
        <p className="text-black mt-3 font-normal">
          CALLMEDILIFE is currently issuing signup coupons to every new user who
          downloads the app and signs up. Users can use these coupons to save on
          their future orders on CALLMEDILIFE. Below are the terms and
          conditions for these coupons:
        </p>
        <ol className="list-decimal p-2 sm:p-0">
          <li>
            <p className="text-black mt-3 font-normal">
              Signup coupons will be issued only once per user irrespective of
              the number of time user downloads the app through single or
              multiple app stores.
            </p>
          </li>
          <li>
            <p className="text-black mt-3 font-normal">
              Not applicable on signup through web or for orders placed through
              phone channel.
            </p>
          </li>
          <li>
            <p className="text-black mt-3 font-normal">
              Currently users will get signup coupons worth Rs.200. However,
              this may change atCALLMEDILIFE's discretion without prior
              intimation
            </p>
          </li>
          <li>
            <p className="text-black mt-3 font-normal">
              Coupons will be credited to your CALLMEDILIFE account once your
              reports for the tests in the first order are uploaded. In case of
              partial sample collection coupons will be issued for only the
              amount for which sample was collected, remainder coupons will be
              issued as and when the sample collection happens. For partly
              cancelled orders, only the proportionate worth of coupons will be
              issued and not the whole order value.
            </p>
          </li>
          <li>
            <p className="text-black mt-3 font-normal">
              The cart value should be kept at Rs. 500 minimum in order to
              redeem the first order coupons. 6. All the general coupons terms
              and conditions mentioned above are applicable on first order
              coupons.
            </p>
          </li>
          <li>
            <p className="text-black mt-3 font-normal">
              Coupon will be issued in the form of codes of various
              denominations cumulatively amounting to Rs.200.
            </p>
          </li>
          <li>
            <p className="text-black mt-3 font-normal">
              CALLMEDILIFE reserves the right to change or withdraw this offer
              at their own discretion without any prior intimation.
            </p>
          </li>
        </ol>
        <p className="text-black mt-3 font-medium text-lg underline">
          Terms and conditions for subscriptions
        </p>
        <ol className="list-decimal p-2 sm:p-0">
          <li>
            <p className="text-black mt-3 font-normal">
              The subscription can only be availed for the specific test or
              package and is non interchangeable. Added tests will be included
              with an overhead charge.
            </p>
          </li>
          <li>
            <p className="text-black mt-3 font-normal">
              Subscription is linked to the customer's account and they can use
              the subscription to book those tests or package for themselves or
              for friends and family members. Subscription is non transferable.
            </p>
          </li>
          <li>
            <p className="text-black mt-3 font-normal">
              Subscription cannot be cancelled. Individual tests/package under
              the subscription can be rescheduled but cannot be cancelled.
            </p>
          </li>
          <li>
            <p className="text-black mt-3 font-normal">
              Additional tests requested at the time of sample collection should
              be paid for separately.
            </p>
          </li>
          <li>
            <p className="text-black mt-3 font-normal">
              In case a package or test is discontinued, CALLMEDILIFE will offer
              substitute test ofequivalent or greater value to the customer. If
              customers are not satisfied with the substitute offered they will
              be offered a refund on a pro rata basis.
            </p>
          </li>
          <li>
            <p className="text-black mt-3 font-normal">
              Coupons are not applicable on subscription orders.
            </p>
          </li>
          <li>
            <p className="text-black mt-3 font-normal">
              Subscription bought is for a defined period and will expire at the
              end of the period. Unused portion of the subscription will stand
              cancelled at the end of the subscription.
            </p>
          </li>
          <li>
            <p className="text-black mt-3 font-normal">
              Subscription start date is the date on which subscription was
              bought and end date is dependent on the tenure for which
              subscription is bought
            </p>
          </li>
          <li>
            <p className="text-black mt-3 font-normal">
              CALLMEDILIFE will make necessary efforts in the form of email and
              SMS communication to remind customers of their upcoming tests
              under the subscription. However, booking has to be made by the
              customer to avail the services under subscription
            </p>
          </li>
        </ol>
        <p className="text-black mt-3 font-medium text-lg underline">
          T&C FOR PRODUCT AND SERVICE PAGE
        </p>
        <p className="text-black mt-3 font-normal">
          Every user agrees and undertakes to abide by the following principles
          upon use of any platform. No user shall host, display, upload, modify,
          publish, transmit, update or share any information which
        </p>
        <ol className="list-decimal p-2 sm:p-0">
          <li>
            <p className="text-black mt-3 font-normal">
              Belongs to another person and to which the user does not have any
              right to.
            </p>
          </li>
          <li>
            <p className="text-black mt-3 font-normal">
              Is grossly harmful, harassing, blasphemous; defamatory, obscene,
              pornographic, paedophilic, libelous, invasive of another's
              privacy, hateful, or racially, ethnically objectionable,
              disparaging, relating or encouraging money laundering or gambling,
              or otherwise unlawful in any manner whatever.
            </p>
          </li>
          <li>
            <p className="text-black mt-3 font-normal">
              Harms minors in any way.
            </p>
          </li>
          <li>
            <p className="text-black mt-3 font-normal">
              Infringes any patent, trademark, copyright or other proprietary
              rights.
            </p>
          </li>
          <li>
            <p className="text-black mt-3 font-normal">
              Violates any law for the time being in force.
            </p>
          </li>
          <li>
            <p className="text-black mt-3 font-normal">
              Deceives or misleads the addressee about the origin of such
              messages or communicates any information which is grossly
              offensive or menacing in nature.
            </p>
          </li>
          <li>
            <p className="text-black mt-3 font-normal">
              Impersonates another person.
            </p>
          </li>
          <li>
            <p className="text-black mt-3 font-normal">
              Contains software viruses or any other computer code, files or
              programs designed to Interrupt, destroy or limit the functionality
              of any computer resource.
            </p>
          </li>
          <li>
            <p className="text-black mt-3 font-normal">
              By submitting our webform, you agree to receive promotional calls on the number shared, and such calls and SMS would be coming from a third-party platform.
            </p>
          </li>
        </ol>
        <p className="text-black mt-3 font-medium text-lg underline">
          GOVERNING LAW AND DISPUTE RESOLUTION
        </p>
        <p className="text-black mt-3 font-normal">
          These Terms and Conditions shall be governed by the provisions of
          Indian law and shall be subject to the exclusive jurisdiction of
          courts situated at [Mumbai, Maharashtra], India. In the event of any
          dispute, controversy or claim arising out of, relating to, or in
          connection with these Terms and Conditions and Privacy Policy, or the
          formation, existence, negotiation or interpretation of these Terms and
          Conditions and Privacy Policy (Dispute), the representatives of
          CALLMEDILIFE and the User shall amicably resolve the Dispute in good
          faith, as soon as either party is apprised of the existence of a
          Dispute.
        </p>
        <p className="text-black mt-3 font-normal">
          In the event CALLMEDILIFE and the user are unable to resolve the
          Dispute within 30 days of address during registration. Our database
          stores the data to identify the customer during further them
          commencing discussions to amicably settle the dispute, the Dispute
          shall be referred to be resolved through arbitration. The arbitration
          proceedings shall be conducted in accordance payments. With provisions
          of the (Indian) Arbitration and Conciliation Act, 1996. These Terms
          and Conditions and the rights and obligations of the parties shall
          remain in full force and effect pending the award in such arbitration
          proceeding.
        </p>
      </div>
      <Footer />
    </>
  );
}
export default Terms;
