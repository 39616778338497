import Footer from "./layouts/Footer";

import SectionHeading from "./SectionHeading";
import PriceCardWithoutImg from "./PriceCardWithoutImg";

import arrow from "./images/arrow.png";
import NewTopNavBar from "./NewTopNavBar";

import React, { useEffect } from "react";
import { setAlllabtestgroups } from "../redux/actions/AlllabtestgroupActions";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";

function AllTests() {
  const labtestGroup = useSelector(
    (state) => state.allLabtestgroup.alllabtestgroup
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchLabtestgroups = async () => {
      try {
        const res = await axios.get(
          process.env.REACT_APP_API_URL + `/api/labtestGroups`
        );
        dispatch(setAlllabtestgroups(res.data.labtestGroups));
      } catch (err) {
        console.log("Err", err);
      }
    };
    fetchLabtestgroups();
  }, [dispatch]);

  return (
    <>
      {/* <TopNavBar /> */}
      <NewTopNavBar />

      <div className="max-w-7xl mx-auto px-4 bg-transparent-200 sm:px-6 lg:px-4 py-12">
        <div>
          <SectionHeading title="All Lab Tests" />
        </div>
        <div className="mt-7 grid grid-cols-1 md:grid-cols-4 sm:grid-cols-3 md:justify-items-center sm:justify-items-center ">
          {labtestGroup.slice(0, 13).map((cmlLabtest) => {
            const { id, name, sellingPrice, mrpPrice } = cmlLabtest;
            return (
              <div key={id}>
                <PriceCardWithoutImg
                  title={name}
                  subtxt1="20% Off"
                  offer={mrpPrice}
                  price={sellingPrice}
                  button="Book Now"
                  route="/contactus"
                />
              </div>
            );
          })}
        </div>
        <div className="mt-16">
          <p className="text-3xl font-medium tracking-wide text-gray-800 dark:text-white md:text-3xl">
            Booking Procedure:
          </p>
          <ul className="list-disc mt-3 ml-7">
            <li>
              Opt for a package of your choice and fill the necessary
              information in the form.
            </li>
            <li>
              A CallMediLife agent will contact you and make an appointment for
              you.
            </li>
            <li>
              A paramedical staff member will come to collect your blood sample
              at your home.
            </li>
            <li>
              Reports will be emailed in a soft copy within 48 hours. In case,
              you need a hard copy, it will be sent to your residence in 2-3
              business days.
            </li>
          </ul>
        </div>
        <div className="mt-6">
          <div className="flex gap-2">
            <img src={arrow} className="w-4 h-4 mt-2" alt="arrow" />
            <p className="font-semibold text-lg"> Why Choose CallMediLife?</p>
          </div>
          <p className="mt-1 ml-2">
            CallMediLife believes in putting out reports that are 100% accurate.
            Also, it has made its name in healthcare industry over the years.
          </p>
        </div>
        <div className="mt-4">
          <div className="flex gap-2">
            <img src={arrow} className="w-4 h-4 mt-2" alt="arrow" />
            <p className="font-semibold text-lg"> Free Sample Collection.</p>
          </div>
          <p className="mt-1 ml-2">
            Our certified Phlebotomists will collect samples from the comfort of
            your doorstep.
          </p>
        </div>
        <div className="mt-4">
          <div className="flex gap-2">
            <img src={arrow} className="w-4 h-4 mt-2" alt="arrow" />
            <p className="font-semibold text-xl"> Fully Automated Labs.</p>
          </div>
          <p className="mt-1 ml-2">
            Our test centers share e-reports within 24-48 hours of sample
            collection.
          </p>
        </div>
        <div className="mt-4">
          <div className="flex gap-2">
            <img src={arrow} className="w-4 h-4 mt-2" alt="arrow" />
            <p className="font-semibold text-xl">
              Accurate & Verified Reports.
            </p>
          </div>
          <p className="mt-1 ml-2">
            Our fully equipped Labs feature state-of-the art technologies for
            highest accuracy.
          </p>
        </div>
        <div className="mt-4">
          <div className="flex gap-2">
            <img src={arrow} className="w-4 h-4 mt-2" alt="arrow" />
            <p className="font-semibold text-xl">
              Offers and Affordable prices.
            </p>
          </div>
          <p className="mt-1 ml-2">
            We are giving the best discount on all health check-up packages.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default AllTests;
