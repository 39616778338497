import BookHealthCare from "./BookHealthCare";
import Footer from "./layouts/Footer";

import contactus from "./images/contactus.png";
import NewTopNavBar from "./NewTopNavBar";

function ContactUs() {
  return (
    <>
      {/* <TopNavBar /> */}
      <NewTopNavBar />
      <div className="max-w-7xl mx-auto px-4 bg-transparent-200 sm:px-6 lg:px-4 py-12">
        <p className="text-4xl font-medium text-indigo-900 text-center">
          Contact Us
        </p>
        <div className="md:flex sm:p-4 gap-4">
          <div className="flex-1">
            <img src={contactus} alt="Contact Us" className="hidden md:block" />
          </div>
          <div className="flex-1">
            <BookHealthCare />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default ContactUs;
