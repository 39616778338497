// IMAGES

import pharma from "./pharma.png";
import testimonial from "./testimonial.png";
import DrVankt from "./DrVankt.jpg";

// COMPONENTS

import Footer from "../../components/layouts/Footer";
import Testimonial from "../../components/Testimonial";
import SectionBgImgTxtBtn from "../../components/SectionBgImgTxtBtn";
import ConsultDoctor from "./consult-doctor/ConsultDoctor";
// import CheckUpsPackages from "./check-ups-packages/CheckUpsPackages";
import NewTopNavBar from "../../components/NewTopNavBar";
import NewGoldPlan from "./gold-plan/NewGoldPlan";
import MenuSectionMobileScreen from "../../components/MenuSectionMobileScreen";
import SectionHeading from "../../components/SectionHeading";
import { Link } from "react-router-dom";
import PriceCardWithImg from "../../components/PriceCardWithImg";
import { useSelector } from "react-redux";

function Home() {
  const packages = useSelector((state) => state.allPackages.packages);

  return (
    <>
      {/*NAVIGATION BAR*/}
      <NewTopNavBar />

      {/* BODY START */}
      <div className="max-w-7xl mx-auto px-4 bg-transparent-200 sm:px-6 lg:px-4 py-12">
        <br></br>

        {/* NEW MENU SECTION FOR MOBILE SCREEN */}
        <MenuSectionMobileScreen />

        {/*NEW SECTION - GOLD PLAN */}
        <NewGoldPlan />

        {/* NEW SECTION - CONSULT DOCTORS*/}
        <ConsultDoctor
          title="Consult Doctor"
          subtitle="Consult with Top Doctors"
        />

        <br></br>
        <br></br>

        {/* NEW SECTION CHECKUP PACKAGES*/}
        {/* <CheckUpsPackages /> */}
        <div>
          <div className="flex justify-between">
            <SectionHeading title="Popular Health Checkup Packages" />
            <Link to="/allPackages">
              <p className="text-lg mt-1 font-medium tracking-wide text-indigo-900 dark:text-white md:text-xl hover:underline mr-6">
                See All Packages
              </p>
            </Link>
          </div>
          <div className="flex flex-col mx-2 md:flex-row sm:flex-col sm:gap-2 md:gap-2 gap-4 mt-12">
            {packages.slice(0, 3).map((cmlpackage) => {
              const { id, name, description, image, sellingPrice, mrpPrice } =
                cmlpackage;
              return (
                <PriceCardWithImg
                  key={id}
                  image={image}
                  name={name}
                  description={description}
                  sellingPrice={sellingPrice}
                  mrpPrice={mrpPrice}
                  button="Book Now"
                  route="/contactus"
                  route1={`/package-lab-test-group/` + id}
                />
              );
            })}
          </div>
        </div>

        <br></br>
        <br></br>

        {/* NEW SECTION - PHARMACY */}
        <SectionBgImgTxtBtn
          image={pharma}
          title="Pharmacy"
          subtext="Get Medicine Delivered at your door step."
          button="Order Now"
          route="/pharmacy"
        />

        {/* TESTIMONIAL */}
        <div className="mt-16">
          <p className="text-center text-3xl font-medium text-gray-800 dark:text-white">
            Testimonial
          </p>
          <p className="text-center mb-12 text-xl font-normal text-gray-400 dark:text-gray-200">
            User Experience for all services provied by us.
          </p>
          <div className="md:flex gap-4 py-2">
            <Testimonial
              image={DrVankt}
              title="Dr. G Venkateshwar"
              description="CallMediLife Healthcare has created a trust in the doctors and patients alike for high quality and reliability in diagnostics. Keep up the good work. Wishing you all, all the best."
            />
            <Testimonial
              image={testimonial}
              title="Mr Swapnesh Shinde"
              description="CallMediLife gives us great competitive prices, and quick accurate results with online access to our numbers. but the thing I'm most impressed with is the Customer Service. Always energetic, cheerful, helpful, accurate and quick. A welcome anomaly in today's world."
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Home;
