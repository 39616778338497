import Footer from "./layouts/Footer";
import NewTopNavBar from "./NewTopNavBar";
import SectionHeading from "./SectionHeading";

import { accordionData } from "./AccordionData";
import Accordion from "./Accordion";

import React, { useState } from "react";

function FAQS() {
  const [isActive, setIsActive] = useState(false);

  return (
    <>
      <NewTopNavBar />

      <div className="max-w-7xl mx-auto px-4 bg-transparent-200 sm:px-6 lg:px-4 py-12">
        <SectionHeading title="FAQs" />

        <div className="mt-6">
          <div className="max-w-screen">
            {accordionData.map(({ title, content }) => (
              <Accordion title={title} content={content} />
            ))}
          </div>

          <div
            className="flex justify-between bg-gray-200 p-4 border border-red-500 rounded hover:bg-gray-300"
            onClick={() => setIsActive(!isActive)}
          >
            <div> How do I print a PDF voucher?</div>
            <div>{isActive ? "-" : "+"}</div>
          </div>

          {isActive && (
            <div className="p-4 bg-white border border-red-300 rounded">
              <p className="font-bold">
                <i className="fa fa-asterisk text-red-500" /> Firstly, how to
                find your Voucher:
              </p>
              <p className="ml-1">
                1. Check your email after completing the purchase.
              </p>
              <p className="ml-1">
                2. Not there? Check your junk/spam folder (it may have been
                redirected there).
              </p>
              <p className="ml-1">
                3. Checked your junk/spam and still no Voucher? Log on to our
                website and download the pdf from your account.
              </p>
              <p className="font-bold mt-2">
                <i className="fa fa-asterisk text-red-500" /> To download your
                Voucher in the PDF format please follow the steps below, which
                apply for any Voucher you purchase:
              </p>
              <ul className="list-disc">
                <li className="ml-6">Log on to www.callmedilife.com</li>
                <li className="ml-6">
                  Click on the login button at the extreme right hand corner of
                  the blue ribbon across the page (below the cities listing).
                </li>
                <li className="ml-6">
                  Enter your username (the email address registered with us) and
                  your password.
                </li>
                <li className="ml-6">
                  Once logged in, please click on the 'My Orders' section of 'My
                  Account'
                </li>
                <li className="ml-6">
                  Use the icon below the relevant Voucher to download and print
                </li>
              </ul>
              <p className="font-bold mt-2">
                <i className="fa fa-asterisk text-red-500" /> Can I give this
                Voucher as a gift?
              </p>
              <ul className="list-disc">
                <li className="ml-6">
                  Yes - you can gift a Voucher to whomever you want. Don't worry
                  if the name is on the Voucher you are gifting - as long as the
                  number hasn't been used yet, your friend won't have a problem
                  using it.
                </li>
                <li className="ml-6">
                  The fine print says "Limit 1 per person" and "May buy
                  multiples as gifts." Can I buy myself some Vouchers as gifts
                  to myself and use more than one?
                </li>
                <li className="ml-6">
                  No. If the fine print says this, you can only redeem one. If
                  you buy more than one then you have to give them away as gifts
                  to other people.
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
}

export default FAQS;
